import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import "./style.css";
import ModalApe from "../ModalApe/ModalApe.js";
import Checkbox from "./../Checkbox/Checkbox.js";
import FilterSidebar from "./../FilterSidebar/FilterSidebar.js";
import CollectionToggle from "./../CollectionToggle/CollectionToggle.js";
import Pagination from "./../Pagination/Pagination.js";
import InfoIcon from "./../InfoIcon/InfoIcon.js";
import DialogModal from "./../DialogModal/DialogModal.js";
import * as CollectionsMetadata from "./../../collectionMetada/collectionsMetadata.js";
import { NotificationManager } from "react-notifications";
import filter_icon from "../../images/filter-icon.png";
import contractAddresses from "../../contractsData/addresses.json";
import { ethers } from "ethers";

const { BigNumber } = ethers;

const S3_OG = "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/";
const S3_LIL =
  "https://apedao-images.s3.eu-central-1.amazonaws.com/lilapes-demo/";

const OG_IMAGE_IPFS_URL = "QmNbZSywnbrf4HSAzDX1jvdvwCkHKgRACChZXF4j1t3X4S";

const OG_BASE_PRICE = 500;
const LIL_BASE_PRICE = 100;

const MAX_RECORDS_PER_PAGE = 20;

const APES_FILTER_API = "https://stats.apedao.finance:8051/v1/filter_og_apes2?";

const initialMaxPages = window.innerWidth <= 500 ? 5 : 10;

function Vaults({
  vault,
  shimmerSeaRouter,
  apeDAOMarket,
  fees,
  balance,
  shimmerBalance,
  account,
  smrERC20,
}) {
  const [txWait, setTxWait] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ogItems, setOGItems] = useState([]);
  const [ogItemsLoaded, setOGItemsLoaded] = useState(false);
  const [lilItems, setLilItems] = useState([]);
  const [lilItemsLoaded, setLilItemsLoaded] = useState(false);
  const [smrChecked, setSMRChecked] = useState(false);
  const [randomBuyModalShow, setRandomBuyModalShow] = useState(false);
  const [targetBuyModalShow, setTargetBuyModalShow] = useState(false);
  const [targetBuySMRModalShow, setTargetBuySMRModalShow] = useState(false);
  const [randomBuySMRModalShow, setRandomBuySMRModalShow] = useState(false);
  const [smrAPEinPrice, setSMRAPEinPrice] = useState("N/A");
  const [smrNeeded, setSMRNeeded] = useState(0);
  const [ogApesSupply, setOgApesSupply] = useState("---");
  const [lilApesSupply, setLilApesSupply] = useState("---");
  const [collectionToLoad, setCollectionToLoad] = useState("OG APES");
  const [cardClicked, setCardClicked] = useState(false);
  const [mapCardClicked, setMapCardClicked] = useState(new Map());
  const [selectedAPEinPrice, setSelectedAPEinPrice] = useState(0);
  const [startFilter, setStartFilter] = useState(true);
  const [filterActive, setFilterActive] = useState(false);
  const [filteredApeIds, setFilteredApeIds] = useState([]);
  const [filterReset, setFilterReset] = useState(false);
  const [dialogModalShow, setDialogModalShow] = useState(false);
  const [rightFilterVisible, setRightFilterVisible] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);
  const [isTargetApprove, setIsTargetApprove] = useState(false);

  const [maxPages, setMaxPages] = useState(initialMaxPages);

  const [isMounted, setIsMounted] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(MAX_RECORDS_PER_PAGE);

  const modalTitle = collectionToLoad === "OG APES" ? "OG APE" : "LIL' APE";
  const modalCollectionPrice =
    collectionToLoad === "OG APES" ? OG_BASE_PRICE : LIL_BASE_PRICE;

  const filterCollection =
    collectionToLoad === "OG APES" ? "&collection=1" : "&collection=2";

  const loadVaultFilteredOGApes = (apeIds, filterRes) => {
    loadVaultFilteredApes(apeIds, filterRes, "OG");
  };

  const loadVaultFilteredLilApes = (apeIds, filterRes) => {
    loadVaultFilteredApes(apeIds, filterRes, "Lil");
  };

  const handleCollectionChange = (collection) => {
    setCollectionToLoad(collection);
  };

  const handleRightSidebarFilterClose = () => {
    setRightFilterVisible(false);
  };

  const handleFilterNumberChange = async (value) => {
    if (value === 0) {
      // Fitler has been cleared
      if (collectionToLoad === "OG APES") {
        await loadVaultOGApes();
      } else if (collectionToLoad === "LIL' APES") {
        await loadVaultLilApes();
      }
      return;
    }

    setFilteredApeIds([value]);
    setStartFilter(true);
    setFilterReset(true);

    if (collectionToLoad === "OG APES") {
      loadVaultFilteredOGApes([value], true);
    } else if (collectionToLoad === "LIL' APES") {
      loadVaultFilteredLilApes([value], true);
    }
  };

  const handleFilterChange = async (selectedValues) => {
    try {
      if (!Object.values(selectedValues).some((array) => array.length > 0)) {
        if (collectionToLoad === "OG APES") {
          setOGItems([]);
        } else if (collectionToLoad === "LIL' APES") {
          setLilItems([]);
        }
        setStartFilter(false);
        setFilterActive(false);
        return;
      }

      setFilterActive(true);

      const filteredItems = Object.entries(selectedValues)
        .filter(([name, subItems]) => subItems.length > 0)
        .map(([name, subItems]) => `${name}=${subItems.join(",")}`)
        .join("&");

      const response = await fetch(
        APES_FILTER_API + filteredItems + filterCollection
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      let apeIds = [];
      const filterKey = collectionToLoad === "OG APES" ? "Ape" : "Lil' Ape";

      apeIds = data.filt_apes
        .filter((value) => value.startsWith(filterKey))
        .map((value) => value.split("#")[1]);

      setFilteredApeIds(apeIds);
      setStartFilter(true);
      setFilterReset(true);

      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        if (collectionToLoad === "OG APES") {
          loadVaultFilteredOGApes(apeIds, true);
        } else if (collectionToLoad === "LIL' APES") {
          loadVaultFilteredLilApes(apeIds, true);
        }
      }
    } catch (error) {
      NotificationManager.error(
        "Can't retrieve filtered data right now",
        "ERROR",
        5000
      );
      console.log(error);
    }
  };

  function handleCardClicked(item) {
    // This is required to make the map update trigger a re-render
    let count = 0;
    let tempMap = new Map(Array.from(mapCardClicked).slice());
    tempMap.set(item.itemId, !tempMap.get(item.itemId));
    setMapCardClicked(tempMap);
    for (let i = 0; i < Array.from(tempMap).length; ++i) {
      if (Array.from(tempMap)[i][1]) {
        ++count;
      }
    }

    if (collectionToLoad === "OG APES") {
      setSelectedAPEinPrice(count * OG_BASE_PRICE);
    } else if (collectionToLoad === "LIL' APES") {
      setSelectedAPEinPrice(count * LIL_BASE_PRICE);
    }

    if (count > 0) {
      setCardClicked(true);
      return;
    }
    setCardClicked(false);
    mapCardClicked.clear();
    setMapCardClicked(mapCardClicked);
  }

  function handleInfoIconClick() {
    setDialogModalShow(true);
  }

  function removeClickedItem(item) {
    let aux = new Map(Array.from(mapCardClicked).slice());

    aux.delete(item);

    if (collectionToLoad === "OG APES") {
      setSelectedAPEinPrice(selectedAPEinPrice - OG_BASE_PRICE);
    } else if (collectionToLoad === "LIL' APES") {
      setSelectedAPEinPrice(selectedAPEinPrice - LIL_BASE_PRICE);
    }

    setMapCardClicked(aux);
    if (aux.size === 0) {
      setTargetBuyModalShow(false);
      setCardClicked(false);
    }
  }

  const loadVaultFilteredApes = async (apeIds, filterRes, type) => {
    let reset = filterRes || filterReset ? true : false;
    const items = type === "OG" ? ogItems : lilItems;
    const setItems = type === "OG" ? setOGItems : setLilItems;
    const setItemsLoaded = type === "OG" ? setOGItemsLoaded : setLilItemsLoaded;
    const setApesSupply = type === "OG" ? setOgApesSupply : setLilApesSupply;
    const apesMetadata =
      type === "OG"
        ? CollectionsMetadata.ogApeMetadata
        : CollectionsMetadata.lilApeMetadata;
    const holdingsIndex = type === "OG" ? 0 : 1;

    if (reset) {
      setFilterReset(false);
    }

    const offset = (currentPage - 1) * recordsPerPage;

    if (offset < items.length && !startFilter && !reset) {
      if (items[offset + 1] != null) {
        return;
      }
    }

    const holdings = await vault.allHoldings(holdingsIndex);
    const holdingsToStrings = holdings.map((item) => String(item));

    const filteredApesArray = apeIds.filter((apeId) =>
      holdingsToStrings.includes(apeId.toString())
    );

    const filteredMetadata = filteredApesArray
      .slice(offset, offset + recordsPerPage)
      .map((apeId) => {
        const metadata = apesMetadata[apeId - 1];
        return {
          itemId: metadata.edition,
          name: metadata.name,
          image:
            type === "OG"
              ? metadata.image.replace(
                  "ipfs://NewUriToReplace",
                  "ipfs://" + OG_IMAGE_IPFS_URL
                )
              : metadata.image,
        };
      });

    const itemsToConcat = filteredMetadata.concat(
      new Array(
        Math.max(0, offset + recordsPerPage - filteredMetadata.length)
      ).fill(null)
    );

    if (offset >= items.length) {
      const diff = offset - items.length;
      items.push(...new Array(diff).fill(null));
    }

    if (startFilter || reset) {
      setItems(itemsToConcat);
      setStartFilter(false);
    } else {
      setItems(items.slice(0, offset).concat(itemsToConcat));
    }

    setItemsLoaded(true);
    setApesSupply(filteredApesArray.length);
    setLoading(false);
  };

  const loadVaultOGApes = async () => {
    const offset = (currentPage - 1) * recordsPerPage; // calculate offset based on current page

    if (offset < ogItems.length) {
      if (ogItems[offset + 1] != null) {
        return;
      }
    }

    setLoading(true);
    const holdings = await vault.allHoldings(0);
    const aux = [];

    for (
      let i = offset;
      i < holdings.length && i < offset + recordsPerPage;
      ++i
    ) {
      const metadata = CollectionsMetadata.ogApeMetadata[holdings[i] - 1];

      const updatedImage = metadata.image.replace(
        "ipfs://NewUriToReplace",
        "ipfs://" + OG_IMAGE_IPFS_URL
      );

      aux.push({
        itemId: metadata.edition,
        name: metadata.name,
        image: updatedImage,
      });
    }

    // If the array is empty or the offset is greater than the array length, add null values
    if (offset >= ogItems.length) {
      const diff = offset - ogItems.length;
      for (let i = 0; i < diff; i++) {
        ogItems.push(null);
      }
    }

    // Concatenate the new items to the array starting from the offset position
    if (aux.length > 0) {
      setOGItems(ogItems.slice(0, offset).concat(aux));
    }

    setOGItemsLoaded(true);
    setOgApesSupply(holdings.length);
    setLoading(false);
  };

  const loadVaultLilApes = async () => {
    const offset = (currentPage - 1) * recordsPerPage; // calculate offset based on current page

    if (offset < lilItems.length) {
      if (lilItems[offset + 1] != null) {
        return;
      }
    }

    setLoading(true);
    const holdings = await vault.allHoldings(1);
    const aux = [];

    for (
      let i = offset;
      i < holdings.length && i < offset + recordsPerPage;
      ++i
    ) {
      const metadata = CollectionsMetadata.lilApeMetadata[holdings[i] - 1];

      aux.push({
        itemId: metadata.edition,
        name: metadata.name,
        image: metadata.image,
      });
    }

    // If the array is empty or the offset is greater than the array length, add null values
    if (offset >= lilItems.length) {
      const diff = offset - lilItems.length;
      for (let i = 0; i < diff; i++) {
        lilItems.push(null);
      }
    }

    // Concatenate the new items to the array starting from the offset position
    if (aux.length > 0) {
      setLilItems(lilItems.slice(0, offset).concat(aux));
    }
    setLilItemsLoaded(true);
    setLilApesSupply(holdings.length);
    setLoading(false);
  };

  const buyMultipleOGApes = async () => {
    const isBalanceAvailable = checkAvailableBalance(
      "Target",
      selectedAPEinPrice
    );
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);

    const selectedApes = Array.from(mapCardClicked)
      .filter(([, isSelected]) => isSelected)
      .map(([ape]) => ape);

    try {
      await (
        await vault.redeem(selectedApes.length, selectedApes, 0, {
          gasLimit: 2000000 * selectedApes.length,
          gasPrice: 100000000000,
        })
      ).wait();
      const successMessage =
        selectedApes.length > 1
          ? "NFTs have been bought"
          : "NFT has been bought";
      NotificationManager.success(successMessage, "Success", 5000);

      setCardClicked(false);
      setMapCardClicked(new Map());
      setStartFilter(false);
      setFilterActive(false);
      setOGItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $APEin tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }

    setTxWait(false);
    setTargetBuyModalShow(false);
  };

  const buyMultipleLilApes = async () => {
    const isBalanceAvailable = checkAvailableBalance(
      "Target",
      selectedAPEinPrice
    );
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);

    const selectedApes = Array.from(mapCardClicked)
      .filter(([, isSelected]) => isSelected)
      .map(([ape]) => ape);

    try {
      await (
        await vault.redeem(selectedApes.length, selectedApes, 1, {
          gasLimit: 2000000 * selectedApes.length,
          gasPrice: 100000000000,
        })
      ).wait();
      const successMessage =
        selectedApes.length > 1
          ? "NFTs have been bought"
          : "NFT has been bought";
      NotificationManager.success(successMessage, "Success", 5000);

      setCardClicked(false);
      setMapCardClicked(new Map());
      setStartFilter(false);
      setFilterActive(false);
      setLilItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $APEin tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }

    setTxWait(false);
    setTargetBuyModalShow(false);
  };

  const randomBuyLilApe = async (amount) => {
    const isBalanceAvailable = checkAvailableBalance("Random", amount);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);
    try {
      const tx = await vault.redeem(amount, [], 1, {
        gasLimit: 2000000 * amount,
        gasPrice: 100000000000,
      });
      await tx.wait();

      const message =
        amount > 1 ? "NFTs have been bought" : "NFT has been bought";
      NotificationManager.success(message, "Success", 5000);

      setStartFilter(false);
      setFilterActive(false);
      setLilItems([]);
    } catch (e) {
      if (e.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $APEin tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(e.message);
      }
    }
    setTxWait(false);

    setRandomBuyModalShow(false);
  };

  const randomBuyOGApe = async (amount) => {
    const isBalanceAvailable = checkAvailableBalance("Random", amount);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);
    try {
      const tx = await vault.redeem(amount, [], 0, {
        gasLimit: 2000000 * amount,
        gasPrice: 100000000000,
      });
      await tx.wait();

      const message =
        amount > 1 ? "NFTs have been bought" : "NFT has been bought";
      NotificationManager.success(message, "Success", 5000);

      setStartFilter(false);
      setFilterActive(false);
      setOGItems([]);
    } catch (e) {
      if (e.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $APEin tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(e.message);
      }
    }
    setTxWait(false);

    setRandomBuyModalShow(false);
  };

  const buyMultipleLilApesSMR = async () => {
    const selectedApes = Array.from(mapCardClicked)
      .filter(([, isSelected]) => isSelected)
      .map(([ape]) => ape);

    const priceFetched = calculateSMRperAPEin();
    if (!priceFetched) {
      return;
    }

    const isBalanceAvailable = checkAvailableBalanceSMR(selectedApes.length);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);

    // Check $SMR spending allowance
    try {
      const allowance = await smrERC20.allowance(
        account,
        contractAddresses.apeDAOMarket
      );
      if (allowance.toNumber() < smrNeeded.toNumber()) {
        setApproveModalShow(true);
        setTargetBuySMRModalShow(false);
        setIsTargetApprove(true);
        setTxWait(false);
        return;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error", 5000);
      console.log(error.message);
    }

    try {
      // BUY AND REDEEM

      const path = [
        contractAddresses.shimmerAddress,
        contractAddresses.apeDAOVaultAddress,
      ];
      const tx = await apeDAOMarket.buyAndRedeemSMR(
        selectedApes.length,
        selectedApes,
        smrNeeded,
        path,
        1,
        account,
        { gasLimit: 300000 * selectedApes.length }
      );

      await tx.wait();

      const successMessage =
        selectedApes.length > 1
          ? "NFTs have been bought"
          : "NFT has been bought";
      NotificationManager.success(successMessage, "Success", 5000);

      setCardClicked(false);
      setMapCardClicked(new Map());
      setStartFilter(false);
      setFilterActive(false);
      setLilItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $SMR tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }
    setTxWait(false);

    setTargetBuySMRModalShow(false);
  };

  const buyMultipleOGApesSMR = async (amount) => {
    const selectedApes = Array.from(mapCardClicked)
      .filter(([, isSelected]) => isSelected)
      .map(([ape]) => ape);

    const priceFetched = calculateSMRperAPEin();
    if (!priceFetched) {
      return;
    }

    const isBalanceAvailable = checkAvailableBalanceSMR(selectedApes.length);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);

    // Check $SMR spending allowance
    try {
      const allowance = await smrERC20.allowance(
        account,
        contractAddresses.apeDAOMarket
      );
      if (allowance.toNumber() < smrNeeded.toNumber()) {
        setApproveModalShow(true);
        setTargetBuySMRModalShow(false);
        setIsTargetApprove(true);
        setTxWait(false);
        return;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error", 5000);
      console.log(error.message);
    }

    try {
      // BUY AND REDEEM

      const path = [
        contractAddresses.shimmerAddress,
        contractAddresses.apeDAOVaultAddress,
      ];
      const tx = await apeDAOMarket.buyAndRedeemSMR(
        selectedApes.length,
        selectedApes,
        smrNeeded,
        path,
        0,
        account,
        { gasLimit: 300000 * selectedApes.length }
      );

      await tx.wait();

      const successMessage =
        selectedApes.length > 1
          ? "NFTs have been bought"
          : "NFT has been bought";
      NotificationManager.success(successMessage, "Success", 5000);

      setCardClicked(false);
      setMapCardClicked(new Map());
      setStartFilter(false);
      setFilterActive(false);
      setOGItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $SMR tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }
    setTxWait(false);

    setTargetBuySMRModalShow(false);
  };

  const randomBuyLilApeSMR = async (amount) => {
    const priceFetched = calculateSMRperAPEin(amount);
    if (!priceFetched) {
      return;
    }

    const isBalanceAvailable = checkAvailableBalanceSMR(amount);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);

    // Check $SMR spending allowance
    try {
      const allowance = await smrERC20.allowance(
        account,
        contractAddresses.apeDAOMarket
      );
      if (allowance.toNumber() < smrNeeded.toNumber()) {
        setApproveModalShow(true);
        setTargetBuySMRModalShow(false);
        setIsTargetApprove(false);
        setTxWait(false);
        return;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error", 5000);
      console.log(error.message);
    }

    // Buy with $SMR
    try {
      const path = [
        contractAddresses.shimmerAddress,
        contractAddresses.apeDAOVaultAddress,
      ];
      console.log(smrNeeded);
      const tx = await apeDAOMarket.buyAndRedeemSMR(
        amount,
        [],
        smrNeeded,
        path,
        1,
        account,
        { gasLimit: 300000 * amount }
      );

      await tx.wait();

      const message =
        amount > 1 ? "NFTs have been bought" : "NFT has been bought";
      NotificationManager.success(message, "Success", 5000);

      setStartFilter(false);
      setFilterActive(false);
      setLilItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $SMR tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }
    setTxWait(false);

    setRandomBuySMRModalShow(false);
  };

  const randomBuyOGApeSMR = async (amount) => {
    const priceFetched = calculateSMRperAPEin(amount);
    if (!priceFetched) {
      return;
    }

    const isBalanceAvailable = checkAvailableBalanceSMR(amount);
    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);
    // Check $SMR spending allowance
    try {
      const allowance = await smrERC20.allowance(
        account,
        contractAddresses.apeDAOMarket
      );
      if (allowance.toNumber() < smrNeeded.toNumber()) {
        setApproveModalShow(true);
        setTargetBuySMRModalShow(false);
        setIsTargetApprove(false);
        setTxWait(false);
        return;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error", 5000);
      console.log(error.message);
    }

    try {
      const path = [
        contractAddresses.shimmerAddress,
        contractAddresses.apeDAOVaultAddress,
      ];
      console.log(smrNeeded);
      const tx = await apeDAOMarket.buyAndRedeemSMR(
        amount,
        [],
        smrNeeded,
        path,
        0,
        account,
        { gasLimit: 300000 * amount }
      );

      await tx.wait();

      const message =
        amount > 1 ? "NFTs have been bought" : "NFT has been bought";
      NotificationManager.success(message, "Success", 5000);

      setStartFilter(false);
      setFilterActive(false);
      setOGItems([]);
    } catch (error) {
      if (error.message.includes("burn amount exceeds balance")) {
        NotificationManager.error("Not enough balance", "Error", 5000);
      } else if (error.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (error.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Please check you have enough $SMR tokens or enough Gas is being used.",
          "Error",
          5000
        );
        console.log(error.message);
      }
    }
    setTxWait(false);

    setRandomBuySMRModalShow(false);
  };

  const calculateSMRperAPEin = async (amount) => {
    const auxAmount = amount;
    if (!amount) {
      // Target buy
      const selectedApes = Array.from(mapCardClicked)
        .filter(([, isSelected]) => isSelected)
        .map(([ape]) => ape);
      amount = selectedApes.length;
    }

    const maxRetries = 5;
    let success = false;

    let basePrice;

    if (collectionToLoad === "OG APES") {
      basePrice = OG_BASE_PRICE;
    } else if (collectionToLoad === "LIL' APES") {
      basePrice = LIL_BASE_PRICE;
    }

    for (let i = 0; i < maxRetries; i++) {
      try {
        let amountOut;
        if (amount !== auxAmount) {
          // Target buy; add fees
          amountOut = BigNumber.from(
            Math.ceil(amount * basePrice * (1 + fees[2] / 100))
          ).mul(BigNumber.from("10").pow(18));
        } else {
          amountOut = BigNumber.from(amount * basePrice).mul(
            BigNumber.from("10").pow(18)
          );
        }

        const amountIn = await shimmerSeaRouter.getAmountsIn(amountOut, [
          contractAddresses.shimmerAddress,
          contractAddresses.apeDAOVaultAddress,
        ]);

        const shimmerNeeded =
          amountIn[0].toString().slice(0, -6) +
          "." +
          amountIn[0].toString().slice(-6, -4);

        setSMRNeeded(amountIn[0]);

        let smrPerAPEin;
        if (amount !== auxAmount) {
          // Target buy; add fees
          smrPerAPEin =
            shimmerNeeded / (amount * basePrice * (1 + fees[2] / 100));
        } else {
          smrPerAPEin = shimmerNeeded / (amount * basePrice);
        }

        setSMRAPEinPrice(Number(smrPerAPEin.toFixed(2)));
        success = true;
        break; // exit the loop if the function succeeds
      } catch (error) {
        console.log(`Attempt ${i + 1} failed:`, error);
        if (i === maxRetries - 1) {
          NotificationManager.error(
            "Can't fetch SMR/APEin price. Refresh page or try later.",
            "Error",
            5000
          );
        }
      }
    }
    return success;
  };

  const approveApeDAOMarket = async () => {
    setTxWait(true);
    try {
      let neededSMR;
      if (!isTargetApprove) {
        neededSMR = smrNeeded;
      } else {
        console.log("TARGET APPROVE");
        const smrNeededNumber = smrNeeded.toNumber();
        const smrNeededPlusFeesNumber = smrNeededNumber * (1 + fees[2] / 100);
        const smrNeededPlusFees = BigNumber.from(
          Math.ceil(smrNeededPlusFeesNumber)
        );
        neededSMR = smrNeededPlusFees;
      }

      const approveTx = await smrERC20.approve(
        contractAddresses.apeDAOMarket,
        neededSMR
      );
      await approveTx.wait();
      console.log("approved");

      NotificationManager.success(
        "SMR spending has been approved. You can buy NFTs now.",
        "Success",
        5000
      );
    } catch (e) {
      if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        console.log(e);
        NotificationManager.error(e.message, "Error", 5000);
      }
    }
    setTxWait(false);
    setApproveModalShow(false);
    if (isTargetApprove) {
      setTargetBuySMRModalShow(true);
    } else {
      setRandomBuySMRModalShow(true);
    }
  };

  function handlePaySMR() {
    setSMRChecked(!smrChecked);
  }

  function checkAvailableBalance(type, amount) {
    let hasEnoughBalance = false;
    if (collectionToLoad === "OG APES") {
      hasEnoughBalance =
        type === "Random"
          ? balance >= OG_BASE_PRICE * amount
          : balance >= amount;
    } else if (collectionToLoad === "LIL' APES") {
      hasEnoughBalance =
        type === "Random"
          ? balance >= LIL_BASE_PRICE * amount
          : balance >= amount;
    }
    if (!hasEnoughBalance) {
      NotificationManager.error(
        "You don't have enough $APEin balance",
        "Error",
        5000
      );
    }
    return hasEnoughBalance;
  }

  function checkAvailableBalanceSMR(amount) {
    let hasEnoughBalance = false;

    let basePrice;

    if (collectionToLoad === "OG APES") {
      basePrice = OG_BASE_PRICE;
    } else if (collectionToLoad === "LIL' APES") {
      basePrice = LIL_BASE_PRICE;
    }

    if (shimmerBalance > Number(smrNeeded.toString().slice(0, -6))) {
      hasEnoughBalance = true;
    }

    if (!hasEnoughBalance) {
      NotificationManager.error(
        "You don't have enough $SMR balance",
        "Error",
        5000
      );
    }
    return hasEnoughBalance;
  }

  useEffect(() => {
    setCardClicked(false);
    setMapCardClicked(new Map());
    setCurrentPage(1);

    if (collectionToLoad === "OG APES" && !ogItemsLoaded) {
      loadVaultOGApes();
    } else if (collectionToLoad === "LIL' APES" && !lilItemsLoaded) {
      loadVaultLilApes();
    }
  }, [collectionToLoad]);

  useEffect(() => {
    setLoading(false);
    if (filterActive) {
      if (collectionToLoad === "OG APES") {
        loadVaultFilteredOGApes(filteredApeIds);
      } else if (collectionToLoad === "LIL' APES") {
        loadVaultFilteredLilApes(filteredApeIds);
      }
    } else {
      if (collectionToLoad === "OG APES") {
        loadVaultOGApes();
      } else if (collectionToLoad === "LIL' APES") {
        loadVaultLilApes();
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (isMounted) {
      if (ogItems.length === 0 && !filterActive) {
        loadVaultOGApes();
      }
    } else {
      setIsMounted(true);
    }
  }, [ogItems]);

  useEffect(() => {
    if (isMounted) {
      if (lilItems.length === 0 && !filterActive) {
        loadVaultLilApes();
      }
    } else {
      setIsMounted(true);
    }
  }, [lilItems]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 500) {
        setMaxPages(5);
      } else {
        setMaxPages(10);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const collection = collectionToLoad === "OG APES" ? ogItems : lilItems;
  const currentRecords = collection.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages =
    collectionToLoad === "OG APES"
      ? Math.ceil(ogApesSupply / recordsPerPage)
      : Math.ceil(lilApesSupply / recordsPerPage);

  return (
    <div className="vaults">
      <div>
        {/* ModalApe components for SMR Payment */}

        <ModalApe
          title={`RANDOM BUY ${modalTitle} with SMR`}
          show={randomBuySMRModalShow}
          shown={randomBuySMRModalShow}
          buttonClick={
            collectionToLoad === "OG APES"
              ? randomBuyOGApeSMR
              : randomBuyLilApeSMR
          }
          buttonTitle={"BUY"}
          onHide={() => setRandomBuySMRModalShow(false)}
          clickedItem=""
          loading={txWait}
          random={true}
          collectionPrice={modalCollectionPrice}
          collection={collectionToLoad}
          smrPayment={true}
          smrAPEinPrice={smrAPEinPrice}
          runOnShow={calculateSMRperAPEin}
        />

        <ModalApe
          title={`TARGET BUY ${modalTitle} with SMR`}
          show={targetBuySMRModalShow}
          shown={targetBuySMRModalShow}
          buttonClick={
            collectionToLoad === "OG APES"
              ? buyMultipleOGApesSMR
              : buyMultipleLilApesSMR
          }
          buttonTitle={"BUY"}
          onHide={() => setTargetBuySMRModalShow(false)}
          loading={txWait}
          selectedAPEinPrice={selectedAPEinPrice}
          selectedApes={Array.from(mapCardClicked)
            .filter(([, isSelected]) => isSelected)
            .map(([ape]) => ape)}
          collection={collectionToLoad}
          onRemoveClicked={removeClickedItem}
          fees={fees}
          smrPayment={true}
          smrAPEinPrice={smrAPEinPrice}
          runOnShow={calculateSMRperAPEin}
        />

        <ModalApe
          title={"Enable $SMR"}
          show={approveModalShow}
          buttonClick={approveApeDAOMarket}
          buttonTitle={"APPROVE"}
          onHide={() => setApproveModalShow(false)}
          loading={txWait}
          isApprove={true}
          isTarget={isTargetApprove}
        />

        <ModalApe
          title={`RANDOM BUY ${modalTitle}`}
          show={randomBuyModalShow}
          buttonClick={
            collectionToLoad === "OG APES" ? randomBuyOGApe : randomBuyLilApe
          }
          buttonTitle={"BUY"}
          onHide={() => setRandomBuyModalShow(false)}
          clickedItem=""
          loading={txWait}
          random={true}
          collectionPrice={modalCollectionPrice}
          collection={collectionToLoad}
        />

        <ModalApe
          title={`TARGET BUY ${modalTitle}`}
          show={targetBuyModalShow}
          buttonClick={
            collectionToLoad === "OG APES"
              ? buyMultipleOGApes
              : buyMultipleLilApes
          }
          buttonTitle={"BUY"}
          onHide={() => setTargetBuyModalShow(false)}
          loading={txWait}
          selectedAPEinPrice={selectedAPEinPrice}
          selectedApes={Array.from(mapCardClicked)
            .filter(([, isSelected]) => isSelected)
            .map(([ape]) => ape)}
          collection={collectionToLoad}
          onRemoveClicked={removeClickedItem}
          fees={fees}
        />
      </div>
      <div className="filter-sidebar-left">
        <FilterSidebar
          collection={collectionToLoad}
          onFilterChange={handleFilterChange}
          onFilterApeNumberChange={handleFilterNumberChange}
          isFilterActive={filterActive}
        />
      </div>
      <div className="vault-content-container">
        <div className="vault-subheader">
          <div className="vault-subheader-row1">
            <CollectionToggle
              title1="OG APES"
              title2="LIL' APES"
              onChange={handleCollectionChange}
            />
            <InfoIcon clickFunction={handleInfoIconClick} />
            <DialogModal
              onHide={() => setDialogModalShow(false)}
              show={dialogModalShow}
              content="Buy random ape without fee, or select the one you like for 0% Target Buy fee."
            />
            <button
              className="vault-subheader-button"
              onClick={() => {
                if (cardClicked) {
                  if (smrChecked) {
                    setTargetBuySMRModalShow(true);
                  } else {
                    setTargetBuyModalShow(true);
                  }
                } else {
                  if (smrChecked) {
                    setRandomBuySMRModalShow(true);
                  } else {
                    setRandomBuyModalShow(true);
                  }
                }
              }}
              disabled={txWait}
            >
              {txWait ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "10px" }}
                />
              ) : null}
              {cardClicked ? "Target Buy" : "Random Buy"}
            </button>
            <button
              className="vault-subheader-filter-button"
              onClick={() => {
                setRightFilterVisible(!rightFilterVisible);
              }}
            >
              <img src={filter_icon} alt="filter icon"></img>
            </button>
          </div>
          <div className="vault-subheader-row2">
            <span style={{ alignSelf: "center" }}>
              {collectionToLoad === "OG APES"
                ? `${ogApesSupply} OG Apes in the vault`
                : `${lilApesSupply} Lil Apes in the vault`}
            </span>
            <Checkbox
              label="Pay with SMR"
              value={smrChecked}
              onChange={handlePaySMR}
              isCardClicked={cardClicked}
            />
          </div>
        </div>
        <div className="vault-content">
          {loading ? (
            <div className="loading-container">
              <Spinner animation="border" className="loading-spinner" />
              <p className="loading-text">Loading...</p>
            </div>
          ) : (
            <Row xs={1} md={2} lg={4} className="g-4">
              {currentRecords.map((item, idx) => {
                if (!item) {
                  return null;
                }

                const isClicked = mapCardClicked.get(item.itemId);
                const cardClass = `card-custom${isClicked ? "-clicked" : ""}`;
                const imgSrc =
                  collectionToLoad === "LIL' APES"
                    ? `${S3_LIL}${item.itemId}.png`
                    : `${S3_OG}${item.itemId}.png`;

                return (
                  <Col key={idx} className="card-col-custom">
                    <Card
                      onClick={() => handleCardClicked(item)}
                      className={cardClass}
                    >
                      <Card.Img src={imgSrc} className="card-image-custom" />
                      <Card.Body className="card-body-custom">
                        <Card.Title className="card-title-custom">
                          {item.name.toUpperCase()}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>

        {(collectionToLoad === "OG APES" &&
          ogApesSupply > MAX_RECORDS_PER_PAGE) ||
        (collectionToLoad === "LIL' APES" &&
          lilApesSupply > MAX_RECORDS_PER_PAGE) ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPages={maxPages}
          />
        ) : null}
      </div>
      <div
        className={
          rightFilterVisible
            ? "filter-sidebar-right"
            : "filter-sidebar-right-hidden"
        }
      >
        <FilterSidebar
          collection={collectionToLoad}
          onFilterChange={handleFilterChange}
          onFilterClose={handleRightSidebarFilterClose}
          onFilterApeNumberChange={handleFilterNumberChange}
          isFilterActive={filterActive}
        />
      </div>
    </div>
  );
}

export default Vaults;
