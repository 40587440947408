import "./style.css";

const Checkbox = ({ label, value, onChange, isCardClicked }) => {
  return (
    <label className={"smr-checkbox" + (isCardClicked ? "-card-clicked" : "")}>
      {label}
      <input
        className="smr-checkbox-input"
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
    </label>
  );
};

export default Checkbox;
