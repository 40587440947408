import { Modal, Spinner } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import apein_logo from "../../images/apein_token_2.svg";
import shimmer_logo from "../../images/smr_logo.svg";
import DialogModal from "./../DialogModal/DialogModal.js";
import InfoIcon from "./../InfoIcon/InfoIcon.js";
import "./style.css";

function ModalApe(props) {
  const {
    title,
    clickedItem,
    buttonClick,
    onHide,
    loading,
    random,
    isSell,
    isApprove,
    selectedAPEinPrice,
    selectedApes,
    collectionPrice,
    collection,
    buttonTitle,
    fees,
    onRemoveClicked,
    smrPayment,
    smrAPEinPrice,
    runOnShow,
    shown,
    isTarget,
    allChecked,
    ...rest
  } = props;

  const inputRandomApeNumber = useRef(null);
  const [inputValue, setInputValue] = useState(1);
  const [dialogModalShow, setDialogModalShow] = useState(false);

  const updateInputValue = (event) => {
    let inputValue = parseInt(event.target.value, 10);

    if (inputValue < 0) {
      inputValue = 1;
    } else if (inputValue > 100) {
      inputValue = 100;
    }

    inputRandomApeNumber.current.value = inputValue;
    setInputValue(inputValue);
  };

  function handleInfoIconClick() {
    setDialogModalShow(true);
  }

  function removeClickedItem(item) {
    onRemoveClicked(item);
  }

  useEffect(() => {
    if (shown) {
      if (random) {
        runOnShow(inputValue);
      } else {
        runOnShow(clickedItem);
      }
    }
  }, [shown, inputValue]);

  useEffect(() => {
    if (!props.show) {
      // Resetting input when closing
      setInputValue(1);
    }
  }, [props.show]);

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal-custom"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        <button
          onClick={() => {
            onHide();
            setInputValue(1);
          }}
          className="modal-close-button"
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        {smrPayment && (
          <p
            style={{
              marginBottom: "30px",
            }}
          >
            Current SMR/APEin price is: {smrAPEinPrice} SMR per APEin
          </p>
        )}
        {random ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p>I want to buy </p>
            <div className="input-increment-container">
              {/* <i
                className="arrow up"
                onClick={() => updateInputValue(inputValue + 1)}
              ></i>
              <span>{inputValue}</span>
              <i
                className="arrow down"
                onClick={() => updateInputValue(inputValue - 1)}
              ></i> */}
              <input
                type="number"
                ref={inputRandomApeNumber}
                min="1"
                max="100"
                defaultValue="1"
                onChange={updateInputValue}
                style={{
                  width: "50px",
                  height: "30px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              ></input>
            </div>
            {collection === "OG APES" ? (
              <p>OG Apes for</p>
            ) : (
              <p>Lil' Apes for</p>
            )}
            <div className="modal-balance-info">
              <img
                src={smrPayment ? shimmer_logo : apein_logo} // Conditionally render the logo based on smrPayment
                className="modal-apedao-icon"
                alt=""
              ></img>
              {smrPayment ? (
                <span
                  style={{
                    marginLeft: "10px",
                    fontWeight: "700",
                  }}
                >
                  {(inputValue * collectionPrice * smrAPEinPrice).toFixed(2)}
                </span>
              ) : (
                <span
                  style={{
                    marginLeft: "10px",
                    fontWeight: "700",
                  }}
                >
                  {(inputValue * collectionPrice).toFixed(2)}
                </span>
              )}
            </div>
            {smrPayment ? <p>$SMR tokens</p> : <p> $APEin tokens</p>}
          </div>
        ) : (
          <div>
            {isSell ? (
              <div>
                {isApprove ? (
                  <p>
                    To sell the selected Apes, these need to be approved by the
                    Vault Smart Contract first!
                  </p>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {allChecked ? (
                        <p>I want to sell all my apes</p>
                      ) : (
                        <>
                          {collection === "OG APES" ? (
                            <p>I want to sell OG Ape number:</p>
                          ) : (
                            <p>I want to sell Lil' Ape number:</p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              marginLeft: "5px",
                            }}
                          >
                            {selectedApes.map((item, idx) => {
                              return (
                                <span
                                  key={idx}
                                  style={{
                                    marginBottom: "1rem",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {item}
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeClickedItem(item)}
                                  >
                                    (x)
                                  </span>
                                  {idx !== selectedApes.length - 1 && ","}
                                </span>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {collection === "OG APES" ? (
                        <p>OG Ape: </p>
                      ) : (
                        <p>Lil' Ape: </p>
                      )}
                      <div className="modal-balance-info">
                        <img
                          src={apein_logo}
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        <span
                          style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedAPEinPrice}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>Sell fee: </p>
                      <div className="modal-balance-info">
                        <img
                          src={apein_logo}
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        <span
                          style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedAPEinPrice * (fees[0] / 100)}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "-1rem",
                        }}
                      >
                        <InfoIcon clickFunction={handleInfoIconClick} />
                      </div>
                      <DialogModal
                        onHide={() => setDialogModalShow(false)}
                        show={dialogModalShow}
                        content="Sell fee is 0% and is sent to the Treasury."
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>You will receive: </p>
                      <div className="modal-balance-info">
                        <img
                          src={apein_logo}
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        <span
                          style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedAPEinPrice -
                            selectedAPEinPrice * (fees[0] / 100)}
                        </span>
                      </div>
                      <p> $APEin tokens</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isApprove ? (
                  <p>
                    To buy Apes with $SMR, you need to allow the Smart Contract
                    to handle these for you.
                  </p>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {collection === "OG APES" ? (
                        <p>I want to buy OG Ape number:</p>
                      ) : (
                        <p>I want to buy Lil' Ape number:</p>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          marginLeft: "5px",
                        }}
                      >
                        {selectedApes.map((item, idx) => {
                          return (
                            <span
                              key={idx}
                              style={{
                                marginBottom: "1rem",
                                marginLeft: "5px",
                              }}
                            >
                              {item}
                              <span
                                style={{
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeClickedItem(item)}
                              >
                                (x)
                              </span>
                              {idx !== selectedApes.length - 1 && ","}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {collection === "OG APES" ? (
                        <p>OG Ape: </p>
                      ) : (
                        <p>Lil' Ape: </p>
                      )}
                      <div className="modal-balance-info">
                        <img
                          src={apein_logo}
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        <span
                          style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedAPEinPrice}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>Target buy fee: </p>
                      <div className="modal-balance-info">
                        <img
                          src={apein_logo}
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        <span
                          style={{
                            marginLeft: "10px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedAPEinPrice * (fees[2] / 100)}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "-1rem",
                        }}
                      >
                        <InfoIcon clickFunction={handleInfoIconClick} />
                      </div>
                      <DialogModal
                        onHide={() => setDialogModalShow(false)}
                        show={dialogModalShow}
                        content="Target Buy fee is 0% and is sent to the Treasury."
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>Total cost: </p>
                      <div className="modal-balance-info">
                        <img
                          src={smrPayment ? shimmer_logo : apein_logo} // Conditionally render the logo based on smrPayment
                          className="modal-apedao-icon"
                          alt=""
                        ></img>
                        {smrPayment ? (
                          <span
                            style={{
                              marginLeft: "10px",
                              fontWeight: "700",
                            }}
                          >
                            {(
                              (selectedAPEinPrice +
                                selectedAPEinPrice * (fees[2] / 100)) *
                              smrAPEinPrice
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "10px",
                              fontWeight: "700",
                            }}
                          >
                            {selectedAPEinPrice +
                              selectedAPEinPrice * (fees[2] / 100)}
                          </span>
                        )}
                      </div>
                      {smrPayment ? <p>$SMR tokens</p> : <p> $APEin tokens</p>}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          {loading && (
            <button className="color-button" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "10px" }}
              />
              Loading...
            </button>
          )}
          {!loading && (
            <button
              onClick={() => buttonClick(random ? inputValue : clickedItem)}
              className="color-button"
            >
              {buttonTitle}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalApe;
