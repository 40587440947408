import "./style.css";
import info_icon from "../../images/info-icon.png";

function InfoIcon({ clickFunction }) {
  return (
    <button className="info-icon-container" onClick={clickFunction}>
      <img src={info_icon} alt="info icon"></img>
    </button>
  );
}

export default InfoIcon;
