import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import detectEthereumProvider from "@metamask/detect-provider";
import Home from "./Home/Home.js";
import Vaults from "./Vaults/Vaults.js";
import Navigation from "./Navbar";
import MyApes from "./MyApes/MyApes.js";
import contractAddresses from "../contractsData/addresses.json";
import ApeDAOVaultAbi from "../contractsData/ApeDAOVault.json";
import OGApesNFTAbi from "../contractsData/OGApesNFT.json";
import LilApesNFTAbi from "../contractsData/LilApesNFT.json";
import ShimmerSeaRouterAbi from "../contractsData/IUniswapV2Router02.json";
import ApeDAOMarketAbi from "../contractsData/ApeDAOMarket.json";
import SMRERC20Abi from "../contractsData/IERC20.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Spinner } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import "./App.css";

function App() {
  const [triedConnect, setTriedConnect] = useState(false);
  const [connected, setConnected] = useState(false);
  const [connectLoad, setConnectLoad] = useState(false);
  const [account, setAccount] = useState(null);
  const [apeInBalance, setBalance] = useState("---");
  const [smrBalance, setSMRBalance] = useState("---");
  const [apeDAOVault, setVault] = useState(null);
  const [ogApeNFT, setOGApeNFT] = useState({});
  const [lilApeNFT, setLilApeNFT] = useState({});
  const [vaultFees, setVaultFees] = useState([]);
  const [shimmerSeaRouter, setShimmerSeaRouter] = useState({});
  const [apeDAOMarket, setApeDAOMarket] = useState({});
  const [smrERC20, setSMRERC20] = useState({});

  // MetaMask Login/Connect
  const web3Handler = async () => {
    setTriedConnect(true);
    setConnectLoad(true);
    const ethereumProvider = await detectEthereumProvider();
    // Check if MetaMask is installed on user's browser
    if (ethereumProvider) {
      let accounts;
      try {
        accounts = await ethereumProvider.request({
          method: "eth_requestAccounts",
        });
      } catch (e) {
        NotificationManager.error(e.message, "Error", 2000);
        setConnectLoad(false);
        return;
      }

      const chainIdValue = await ethereumProvider.request({
        method: "eth_chainId",
      });

      if (chainIdValue !== "0x430") {
        try {
          await ethereumProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x430" }],
          });
        } catch (e) {
          if (e.code === 4902) {
            try {
              await ethereumProvider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x430",
                    rpcUrls: ["https://json-rpc.evm.testnet.shimmer.network/"],
                    chainName: "ShimmerEVM Testnet",
                    nativeCurrency: {
                      name: "SMR",
                      symbol: "SMR",
                      decimals: 18,
                    },
                    blockExplorerUrls: [
                      "https://explorer.evm.testnet.shimmer.network/",
                    ],
                  },
                ],
              });
            } catch (e) {
              NotificationManager.error(e.message, "Error", 4000);
              setConnectLoad(false);
              return;
            }
          } else {
            NotificationManager.error(e.message, "Error", 4000);
            setConnectLoad(false);
            return;
          }
        }
        setConnectLoad(false);
      } else {
        setAccount(accounts[0]);
        // Get provider from Metamask
        const provider = new ethers.providers.Web3Provider(ethereumProvider);
        // Set signer
        const signer = provider.getSigner();

        updateSMRBalance(provider, accounts[0]);

        const apeDAOVault = new ethers.Contract(
          contractAddresses.apeDAOVaultAddress,
          ApeDAOVaultAbi.abi,
          signer
        );
        setVault(apeDAOVault);

        const ogApeNFT = new ethers.Contract(
          contractAddresses.ogNFTAddress,
          OGApesNFTAbi.abi,
          signer
        );
        setOGApeNFT(ogApeNFT);

        const lilApeNFT = new ethers.Contract(
          contractAddresses.lilNFTAddress,
          LilApesNFTAbi.abi,
          signer
        );
        setLilApeNFT(lilApeNFT);

        const shimmerSeaRouter = new ethers.Contract(
          contractAddresses.shimmerSeaRouterAddress,
          ShimmerSeaRouterAbi.abi,
          signer
        );
        setShimmerSeaRouter(shimmerSeaRouter);

        const apeDAOMarket = new ethers.Contract(
          contractAddresses.apeDAOMarket,
          ApeDAOMarketAbi.abi,
          signer
        );
        setApeDAOMarket(apeDAOMarket);

        const smrERC20 = new ethers.Contract(
          contractAddresses.shimmerAddress,
          SMRERC20Abi.abi,
          signer
        );
        setSMRERC20(smrERC20);

        console.log("Started contract data retrieval");

        let attempts = 0;
        const maxNumAttempts = 3;

        while (true) {
          try {
            let apeInBalance = (await apeDAOVault.balanceOf(accounts[0]))
              .toString()
              .slice(0, -18);
            if (apeInBalance === "") {
              setBalance(0);
            } else {
              setBalance(apeInBalance);
            }
            attempts = 0;
            break;
          } catch {
            if (++attempts === maxNumAttempts) {
              NotificationManager.error(
                "Unable to retrieve contract data. Retry later.",
                "Error",
                2000
              );
              return;
            }
          }
        }

        console.log("Step 1 contract data retrieval");

        let sellFee = 0;
        let randomBuyFee = 0;
        let targetBuyFee = 0;

        while (true) {
          try {
            sellFee = (await apeDAOVault.mintFee()).toNumber();
            attempts = 0;
            break;
          } catch {
            if (++attempts === maxNumAttempts) {
              NotificationManager.error(
                "Unable to retrieve contract data. Retry later.",
                "Error",
                2000
              );
              return;
            }
          }
        }

        console.log("Step 2 contract data retrieval");

        while (true) {
          try {
            randomBuyFee = (await apeDAOVault.randomRedeemFee()).toNumber();
            attempts = 0;
            break;
          } catch {
            if (++attempts === maxNumAttempts) {
              NotificationManager.error(
                "Unable to retrieve contract data. Retry later.",
                "Error",
                2000
              );
              return;
            }
          }
        }

        console.log("Step 3 contract data retrieval");

        while (true) {
          try {
            targetBuyFee = (await apeDAOVault.targetRedeemFee()).toNumber();
            attempts = 0;
            break;
          } catch {
            if (++attempts === maxNumAttempts) {
              NotificationManager.error(
                "Unable to retrieve contract data. Retry later.",
                "Error",
                2000
              );
              return;
            }
          }
        }

        console.log("Contract data retrieved successfully");
        //setVaultFees([10, 0, 5]);
        setVaultFees([sellFee, randomBuyFee, targetBuyFee]);

        setConnected(true);
        setConnectLoad(false);
      }
    } else {
      // Show alert if Ethereum provider is not detected
      NotificationManager.error(
        "Metamask is not installed. Please install it.",
        "Error",
        2000
      );
      setConnectLoad(false);
    }
  };

  const updateBalance = async (provider, address) => {
    let apeInBalance;
    let apeDAOVaultAux;
    let signer;

    if (apeDAOVault === null) {
      signer = provider.getSigner();
      apeDAOVaultAux = new ethers.Contract(
        contractAddresses.apeDAOVaultAddress,
        ApeDAOVaultAbi.abi,
        signer
      );
      apeInBalance = (await apeDAOVaultAux.balanceOf(address))
        .toString()
        .slice(0, -18);
    } else {
      apeInBalance = (await apeDAOVault.balanceOf(address))
        .toString()
        .slice(0, -18);
    }
    if (apeInBalance === "") {
      apeInBalance = 0;
    }
    setBalance(apeInBalance);
  };

  const updateSMRBalance = async (provider, address) => {
    const auxBalance = await provider.getBalance(address);
    const shimmerBalance =
      auxBalance.toString().slice(0, -18) +
      "." +
      auxBalance.toString().slice(-18, -16);

    if (shimmerBalance === ".") {
      setSMRBalance(0);
    } else {
      setSMRBalance(shimmerBalance);
    }
  };

  useEffect(() => {
    if (
      !connected &&
      !triedConnect &&
      localStorage.getItem("ConnectionStatus") === "Connected"
    ) {
      web3Handler();
    }
  });

  useEffect(() => {
    window.addEventListener("ConnectionStatusDisconnected", () => {
      window.location.reload();
    });
    return () => {
      window.removeEventListener("ConnectionStatusDisconnected", () => {
        window.location.reload();
      });
    };
  }, []);

  useEffect(async () => {
    let provider;
    const ethereumProvider = await detectEthereumProvider();
    if (ethereumProvider) {
      ethereumProvider.on("chainChanged", (chainIdValue) => {
        if (chainIdValue === "0x430") {
          web3Handler();
        } else {
          window.location.reload();
        }
      });

      ethereumProvider.on("accountsChanged", async function (accounts) {
        if (accounts[0] === undefined) {
          setConnected(false);
          setAccount(accounts[0]);
          return;
        }
        setAccount(accounts[0]);
        window.location.reload();
        await web3Handler();
      });

      let accounts;
      accounts = await ethereumProvider.request({
        method: "eth_requestAccounts",
      });

      provider = new ethers.providers.Web3Provider(ethereumProvider);

      provider.on("block", () => {
        updateSMRBalance(provider, accounts[0]);
        updateBalance(provider, accounts[0]);
      });

      return () => {
        ethereumProvider.removeAllListeners();
        provider.removeAllListeners();
      };
    }
  }, []);

  return (
    <HashRouter>
      <div className="App">
        <>
          <Navigation
            web3Handler={web3Handler}
            account={account}
            balance={apeInBalance}
            shimmerBalance={smrBalance}
            setConnectLoad={setConnectLoad}
            vault={apeDAOVault}
            og={ogApeNFT}
            lil={lilApeNFT}
          />
        </>
        <div>
          {!connected ? (
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh",
              }}
            >
              <h3 style={{ display: "flex" }}> Please, connect your wallet</h3>
              <p style={{ display: "flex" }}>
                Please connect your wallet to start buying or selling Apes
              </p>
              {connectLoad ? (
                <button
                  variant="primary"
                  disabled
                  style={{
                    width: "200px",
                    height: "50px",
                    marginTop: "25px",
                  }}
                  className="color-button"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  />
                  Loading...
                </button>
              ) : (
                <button
                  onClick={() => {
                    localStorage.setItem("ConnectionStatus", "Connected");
                    web3Handler();
                  }}
                  style={{
                    marginTop: "25px",
                  }}
                  className="color-button"
                >
                  Connect Wallet
                </button>
              )}
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route
                path="/home"
                element={
                  <Home
                    vault={apeDAOVault}
                    og={ogApeNFT}
                    lil={lilApeNFT}
                    account={account}
                    fees={vaultFees}
                    balance={apeInBalance}
                    shimmerBalance={smrBalance}
                  />
                }
              />
              <Route
                path="/vaults"
                element={
                  <Vaults
                    vault={apeDAOVault}
                    shimmerSeaRouter={shimmerSeaRouter}
                    apeDAOMarket={apeDAOMarket}
                    fees={vaultFees}
                    balance={apeInBalance}
                    account={account}
                    shimmerBalance={smrBalance}
                    smrERC20={smrERC20}
                  />
                }
              />
              <Route
                path="/my-apes"
                element={
                  <MyApes
                    vault={apeDAOVault}
                    og={ogApeNFT}
                    lil={lilApeNFT}
                    account={account}
                    fees={vaultFees}
                    balance={smrBalance}
                  />
                }
              />
            </Routes>
          )}
        </div>
        <NotificationContainer />
      </div>
    </HashRouter>
  );
}

export default App;
