export const filterItemsOG = [
  {
    name: "background",
    label: "Background",
    items: [
      { name: "green", label: "Green" },
      { name: "red", label: "Red" },
      { name: "wallpaper", label: "Wallpaper" },
      { name: "space", label: "Space" },
      { name: "stars", label: "Stars" },
      { name: "pink", label: "Pink" },
      { name: "magenta", label: "Magenta" },
      { name: "tricolor", label: "Tricolor" },
      { name: "painting", label: "Painting" },
    ],
  },
  {
    name: "fur",
    label: "Fur",
    items: [
      { name: "black", label: "Black" },
      { name: "gold", label: "Gold" },
      { name: "blue", label: "Blue" },
      { name: "metal", label: "Metal" },
      { name: "darkBlue", label: "Dark Blue" },
      { name: "purple", label: "Purple" },
      { name: "orange", label: "Orange" },
      { name: "cream", label: "Cream" },
      { name: "rainbow", label: "Rainbow" },
      { name: "gray", label: "Gray" },
      { name: "tan", label: "Tan" },
      { name: "crystal", label: "Crystal" },
      { name: "zebra", label: "Zebra" },
    ],
  },
  {
    name: "mouth",
    label: "Mouth",
    items: [
      { name: "moustache", label: "Moustache" },
      { name: "tongue", label: "Tongue" },
      { name: "boring", label: "Boring" },
      { name: "grin", label: "Grin" },
      { name: "bullet", label: "Bullet" },
      { name: "angry", label: "Angry" },
      { name: "smile", label: "Smile" },
      { name: "diamondGrill", label: "Diamond Grill" },
      { name: "goldenTooth", label: "Golden Tooth" },
    ],
  },
  {
    name: "head",
    label: "Head",
    items: [
      { name: "sheikh", label: "Sheikh" },
      { name: "topHat", label: "Top Hat" },
      { name: "bald", label: "Bald" },
      { name: "mohawk", label: "Mohawk" },
      { name: "headset", label: "Headset" },
      { name: "viking", label: "Viking" },
      { name: "hat", label: "Hat" },
      { name: "bandana", label: "Bandana" },
      { name: "cowboy", label: "Cowboy" },
      { name: "barret", label: "Barret" },
      { name: "strawHat", label: "Straw Hat" },
      { name: "conical", label: "Conical" },
      { name: "armyCap", label: "Army Cap" },
      { name: "fez", label: "Fez" },
      { name: "sailor", label: "Sailor" },
      { name: "crown", label: "Crown" },
    ],
  },
  {
    name: "clothes",
    label: "Clothes",
    items: [
      { name: "farmer", label: "Farmer" },
      { name: "mantle", label: "Mantle" },
      { name: "fruitTanktop", label: "Fruit Tanktop" },
      { name: "leatherJacket", label: "Leather Jacket" },
      { name: "cyborg", label: "Cyborg" },
      { name: "tShirt", label: "T-Shirt" },
      { name: "suit", label: "Suit" },
      { name: "oktoberfest", label: "Oktoberfest" },
      { name: "naked", label: "Naked" },
      { name: "octoTanktop", label: "Octo Tanktop" },
      { name: "shirt", label: "Shirt" },
      { name: "squaredShirt", label: "Squared Shirt" },
      { name: "kimono", label: "Kimono" },
      { name: "army", label: "Army" },
      { name: "dottedTanktop", label: "Dotted Tanktop" },
    ],
  },
  {
    name: "accessories",
    label: "Accessories",
    items: [
      { name: "crossEarring", label: "Cross Earring" },
      { name: "leatherBracelet", label: "Leather Bracelet" },
      { name: "noAccessory", label: "No Accessory" },
      { name: "neonBracelet", label: "Neon Bracelet" },
      { name: "noseRing", label: "Nose Ring" },
      { name: "goatee", label: "Goatee" },
      { name: "goldenBracelet", label: "Golden Bracelet" },
      { name: "goldenEarring", label: "Golden Earring" },
      { name: "diamondEarring", label: "Diamond Earring" },
    ],
  },
  {
    name: "eyes",
    label: "Eyes",
    items: [
      { name: "laser", label: "Laser" },
      { name: "aviators", label: "Aviators" },
      { name: "monocle", label: "Monocle" },
      { name: "3d", label: "3D" },
      { name: "greenAviators", label: "Green Aviators" },
      { name: "tears", label: "Tears" },
      { name: "dizzy", label: "Dizzy" },
      { name: "sleepy", label: "Sleepy" },
      { name: "steampunk", label: "Steampunk" },
      { name: "ogSunglasses", label: "OG Sunglasses" },
      { name: "glasses", label: "Glasses" },
      { name: "vr", label: "VR" },
    ],
  },
  {
    name: "hand",
    label: "Hand",
    items: [
      { name: "axe", label: "Axe" },
      { name: "cigar", label: "Cigar" },
      { name: "pistol", label: "Pistol" },
      { name: "microphone", label: "Microphone" },
      { name: "knife", label: "Knife" },
      { name: "gangSign", label: "Gang Sign" },
      { name: "glass", label: "Glass" },
      { name: "scepter", label: "Scepter" },
      { name: "money", label: "Money" },
      { name: "beer", label: "Beer" },
      { name: "wheat", label: "Wheat" },
      { name: "mobilePhone", label: "Mobile Phone" },
    ],
  },
];

export const filterItemsLil = [
  {
    name: "background",
    label: "Background",
    items: [
      { name: "Shiny", label: "Shiny" },
      { name: "Retro", label: "Retro" },
      { name: "Moon", label: "Moon" },
      { name: "Playground", label: "Playground" },
      { name: "Bubbles", label: "Bubbles" },
      { name: "Shimmer", label: "Shimmer" },
      { name: "Wallpaper", label: "Wallpaper" },
      { name: "Sea", label: "Sea" },
      { name: "Space", label: "Space" },
      { name: "Stars", label: "Stars" },
      { name: "Aquarelle", label: "Aquarelle" },
      { name: "Party", label: "Party" },
      { name: "Bistre Brown", label: "Bistre Brown" },
      { name: "Sunset", label: "Sunset" },
      { name: "English Vermillion", label: "English Vermillion" },
      { name: "Rose Quartz Pink", label: "Rose Quartz Pink" },
      { name: "Sugar Plum", label: "Sugar Plum" },
      { name: "Medium Carmine", label: "Medium Carmine" },
      { name: "Tricolor", label: "Tricolor" },
      { name: "Ocean Green", label: "Ocean Green" },
      { name: "French Lilac", label: "French Lilac" },
      { name: "Blue Cola", label: "Blue Cola" },
      { name: "Mauvelous", label: "Mauvelous" },
      { name: "Dollar Bill", label: "Dollar Bill" },
      { name: "Beer", label: "Beer" },
      { name: "Metallic Blue", label: "Metallic Blue" },
    ],
  },
  {
    name: "back",
    label: "Back",
    items: [
      { name: "Empty", label: "Empty" },
      { name: "Skateboard", label: "Skateboard" },
      { name: "Nerf Rifle", label: "Nerf Rifle" },
      { name: "Wooden Sword", label: "Wooden Sword" },
      { name: "Backpack", label: "Backpack" },
    ],
  },
  {
    name: "fur",
    label: "Fur",
    items: [
      { name: "Blue", label: "Blue" },
      { name: "Orange", label: "Orange" },
      { name: "Cream", label: "Cream" },
      { name: "Dark Blue", label: "Dark Blue" },
      { name: "Metal", label: "Metal" },
      { name: "Purple", label: "Purple" },
      { name: "Black", label: "Black" },
      { name: "Gray", label: "Gray" },
      { name: "Tiger", label: "Tiger" },
      { name: "Chrome", label: "Chrome" },
      { name: "Silver", label: "Silver" },
      { name: "Gold", label: "Gold" },
      { name: "Bronze", label: "Bronze" },
      { name: "Rainbow", label: "Rainbow" },
      { name: "Tan", label: "Tan" },
      { name: "Trippy", label: "Trippy" },
      { name: "fishScales", label: "Fish Scales" },
      { name: "Giraffe", label: "Giraffe" },
      { name: "Crystal", label: "Crystal" },
      { name: "Zebra", label: "Zebra" },
    ],
  },
  {
    name: "clothes",
    label: "Clothes",
    items: [
      { name: "Naked", label: "Naked" },
      { name: "GreenTShirt", label: "Green T Shirt" },
      { name: "Suit", label: "Suit" },
      { name: "StripedPoloTShirt", label: "Striped Polo T Shirt" },
      { name: "RedTShirt", label: "Red T Shirt" },
      { name: "Farmer", label: "Farmer" },
      { name: "EAPoloTShirt", label: "EA$ Polo T Shirt" },
      { name: "BabyBib", label: "Baby Bib" },
      { name: "Tracksuit", label: "Tracksuit" },
      { name: "Hoodie", label: "Hoodie" },
      { name: "WinterJacket", label: "Winter Jacket" },
      { name: "NerfBelt", label: "Nerf Belt" },
      { name: "FlippinBurgersTShirt", label: "Flippin Burgers T Shirt" },
      { name: "Mantle", label: "Mantle" },
      { name: "SportsJersey", label: "Sports Jersey" },
      { name: "Armor", label: "Armor" },
      { name: "Astronaut", label: "Astronaut" },
      { name: "HawaiianShirt", label: "Hawaiian Shirt" },
    ],
  },
  {
    name: "eyes",
    label: "Eyes",
    items: [
      { name: "Tears", label: "Tears" },
      { name: "Surprised", label: "Surprised" },
      { name: "Glasses", label: "Glasses" },
      { name: "Curious", label: "Curious" },
      { name: "Hearts", label: "Hearts" },
      { name: "Sleepy", label: "Sleepy" },
      { name: "Dizzy", label: "Dizzy" },
      { name: "DivaSunglasses", label: "Diva Sunglasses" },
      { name: "Angry", label: "Angry" },
      { name: "Luminous", label: "Luminous" },
      { name: "VR", label: "VR" },
      { name: "Happy", label: "Happy" },
      { name: "Sunglasses", label: "Sunglasses" },
      { name: "3D", label: "3D" },
    ],
  },
  {
    name: "accessories",
    label: "Accessories",
    items: [
      { name: "No Accessory", label: "No Accessory" },
      { name: "Ear Pod", label: "Ear Pod" },
      { name: "Neon Bracelet", label: "Neon Bracelet" },
      { name: "Scarf", label: "Scarf" },
      { name: "FOMO Chain", label: "FOMO Chain" },
      { name: "Pacifer", label: "Pacifer" },
      { name: "WEN MOON Chain", label: "WEN MOON Chain" },
      { name: "ApeDAO Necklace", label: "ApeDAO Necklace" },
    ],
  },
  {
    name: "mouth",
    label: "Mouth",
    items: [
      { name: "Baby Tooth", label: "Baby Tooth" },
      { name: "Grin", label: "Grin" },
      { name: "Angry", label: "Angry" },
      { name: "Missing Tooth", label: "Missing Tooth" },
      { name: "Drool", label: "Drool" },
      { name: "Whistle", label: "Whistle" },
      { name: "Pacifer", label: "Pacifer" },
      { name: "Sad", label: "Sad" },
      { name: "Happy", label: "Happy" },
      { name: "Tongue", label: "Tongue" },
      { name: "Smile", label: "Smile" },
      { name: "Dental Braces", label: "Dental Braces" },
      { name: "Spoon", label: "Spoon" },
      { name: "Bubble Gum", label: "Bubble Gum" },
      { name: "Party Horn", label: "Party Horn" },
      { name: "Golden Tooth", label: "Golden Tooth" },
      { name: "Diamond Grill", label: "Diamond Grill" },
      { name: "Rainbow", label: "Rainbow" },
    ],
  },
  {
    name: "hand",
    label: "Hand",
    items: [
      { name: "Empty", label: "Empty" },
      { name: "Microphone", label: "Microphone" },
      { name: "Winter Glove", label: "Winter Glove" },
      { name: "Scepter", label: "Scepter" },
      { name: "Dart", label: "Dart" },
      { name: "YoYo", label: "YoYo" },
      { name: "Mobile Phone", label: "Mobile Phone" },
      { name: "Money", label: "Money" },
      { name: "Baby Bottle", label: "Baby Bottle" },
      { name: "Squirt Gun", label: "Squirt Gun" },
      { name: "Rattle", label: "Rattle" },
      { name: "Air Horn", label: "Air Horn" },
      { name: "Wind Toy", label: "Wind Toy" },
      { name: "Toy Car", label: "Toy Car" },
      { name: "Nerf Gun", label: "Nerf Gun" },
      { name: "Spray", label: "Spray" },
      { name: "Banana", label: "Banana" },
      { name: "Soda", label: "Soda" },
      { name: "Gamepad", label: "Gamepad" },
      { name: "Game Boi", label: "Game Boi" },
      { name: "OJ", label: "OJ" },
      { name: "Lumi", label: "Lumi" },
    ],
  },
  {
    name: "head",
    label: "Head",
    items: [
      { name: "Bald", label: "Bald" },
      { name: "PartyHat", label: "Party Hat" },
      { name: "Fez", label: "Fez" },
      { name: "SidewaysCap", label: "Sideways Cap" },
      { name: "PUMPVisor", label: "PUMP Visor" },
      { name: "CoconutHat", label: "Coconut Hat" },
      { name: "Bow", label: "Bow" },
      { name: "Cap", label: "Cap" },
      { name: "SportsBandana", label: "Sports Bandana" },
      { name: "CowboyHat", label: "Cowboy Hat" },
      { name: "PropellerHat", label: "Propeller Hat" },
      { name: "Cylinder", label: "Cylinder" },
      { name: "ReversedCap", label: "Reversed Cap" },
      { name: "DUMPVisor", label: "DUMP Visor" },
      { name: "Crown", label: "Crown" },
      { name: "DegenZBandana", label: "DegenZ Bandana" },
      { name: "Beanie", label: "Beanie" },
      { name: "ChristmasHat", label: "Christmas Hat" },
      { name: "StrawHat", label: "Straw Hat" },
      { name: "Viking", label: "Viking" },
      { name: "Ushanka", label: "Ushanka" },
      { name: "SodaHelmet", label: "Soda Helmet" },
      { name: "Banandana", label: "Banandana" },
      { name: "Beret", label: "Beret" },
      { name: "MysticalEye", label: "Mystical Eye" },
      { name: "Sailor", label: "Sailor" },
      ,
    ],
  },
];
