import React, { useState, useEffect } from "react";
import "./style.css";
import * as Constants from "./constants.js";

const FilterSidebar = ({
  collection,
  onFilterChange,
  onFilterClose,
  onFilterApeNumberChange,
  isFilterActive,
}) => {
  const [expandedParents, setExpandedParents] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [filterItems, setFilterItems] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [apeNumberModified, setApeNumberModified] = useState(false);
  const [apeNumber, setApeNumber] = useState("");

  const toggleParent = (name) => {
    setExpandedParents((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleItemSelect = (e) => {
    const { name, value, checked } = e.target;
    setSelectedItems((prevState) => {
      if (checked) {
        return { ...prevState, [name]: [...(prevState[name] || []), value] };
      } else {
        const selected = [...(prevState[name] || [])];
        selected.splice(selected.indexOf(value), 1);
        return { ...prevState, [name]: selected };
      }
    });
  };

  const handleClearAll = () => {
    setSelectedItems({});
    setExpandedParents({});
    setApeNumberModified(false);
    document.getElementById("apeNumber").value = "";
  };

  const handleClearSubItems = (name) => {
    setSelectedItems((prevState) => {
      const { [name]: _, ...rest } = prevState; // remove subitems for this filterItem
      return rest;
    });
    setExpandedParents((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const handleFilterApeNumberChange = (e) => {
    if (e.target.value >= 1 && e.target.value <= 5370) {
      onFilterApeNumberChange(e.target.value);
      setApeNumberModified(true);
      setApeNumber(e.target.value);
    } else {
      setApeNumber("");
    }
  };

  useEffect(() => {
    handleClearAll();
    if (collection === "OG APES") {
      setFilterItems(Constants.filterItemsOG);
    } else if (collection === "LIL' APES") {
      setFilterItems(Constants.filterItemsLil);
    }
  }, [collection]);

  useEffect(() => {
    if (isMounted) {
      onFilterChange(selectedItems);
    } else {
      setIsMounted(true);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!isFilterActive) {
      handleClearAll();
    }
  }, [isFilterActive]);

  return (
    <div>
      <div className="filterHeaderContainer">
        <span className="filterHeaderLabel">FILTERS</span>
        {Object.keys(selectedItems).length > 0 && (
          <button className="filterClearAllButton" onClick={handleClearAll}>
            (Clear All)
          </button>
        )}
        <button
          onClick={() => {
            onFilterClose();
          }}
          className="filter-header-close-button"
        >
          x
        </button>
      </div>
      <ul className="filterList">
        <li className="filterItem" style={{ width: "85%" }}>
          <div className="filter-item-ape-number-button-wrapper">
            <p className="filter-item-ape-number">Ape number</p>
            {apeNumberModified && (
              <button
                className="filterClearButtonItem"
                onClick={() => {
                  setApeNumberModified(false);
                  setApeNumber("");
                  onFilterApeNumberChange(0);
                }}
              >
                (Clear)
              </button>
            )}
          </div>
          <input
            type="number"
            id="apeNumber"
            name="apeNumber"
            placeholder="Enter Ape number"
            value={apeNumber}
            onChange={handleFilterApeNumberChange}
            min={1}
            max={5370}
            className="filter-item-ape-number-input"
          />
        </li>
        {filterItems.map(({ label, name, items: subItems, ...rest }) => (
          <React.Fragment key={name}>
            <li {...rest} className="filterItem">
              <div className="filterItemButtonContainer">
                <button onClick={() => toggleParent(name)}>{label}</button>
                {selectedItems[name] && selectedItems[name].length > 0 && (
                  <button
                    className="filterClearButtonItem"
                    onClick={() => handleClearSubItems(name)}
                  >
                    (Clear)
                  </button>
                )}
                {Array.isArray(subItems) && subItems.length > 0 && (
                  <button
                    onClick={() => toggleParent(name)}
                    className="filterExpandIndicator"
                  >
                    {expandedParents[name] ? "-" : "+"}
                  </button>
                )}
              </div>
              {expandedParents[name] && Array.isArray(subItems) ? (
                <ul className="filterListSubItem">
                  {subItems.map((subItem) => (
                    <li key={subItem.label} className="filterSubItem">
                      <label
                        htmlFor={subItem.label}
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          type="checkbox"
                          id={subItem.label}
                          name={name}
                          value={subItem.label}
                          onChange={handleItemSelect}
                          checked={
                            selectedItems[name] &&
                            selectedItems[name].includes(subItem.label)
                          }
                          style={{
                            marginRight: "15px",
                          }}
                        />
                        {subItem.label}
                      </label>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default FilterSidebar;
