import React, { useState } from "react";
import "./style.css";

function CollectionToggle({ title1, title2, onChange }) {
  const [activeButton, setActiveButton] = useState(title1);

  const handleButtonClick = (title) => {
    if (title !== activeButton) {
      setActiveButton(title);
      onChange(title);
    }
  };

  return (
    <div className="button-container">
      <button
        className={`left-button ${
          activeButton === title1 ? "active-button" : ""
        }`}
        onClick={() => handleButtonClick(title1)}
      >
        {title1}
      </button>
      <button
        className={`right-button ${
          activeButton === title2 ? "active-button" : ""
        }`}
        onClick={() => handleButtonClick(title2)}
      >
        {title2}
      </button>
    </div>
  );
}

export default CollectionToggle;
