import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import "./style.css";
import ActivityList from "../ActivityList/ActivityList";
import Pagination from "./../Pagination/Pagination.js";

const OG_HOLDING_INDEX = 0;
const LIL_HOLDING_INDEX = 1;

const MAX_RECORDS_PER_PAGE = 10;
const MAX_PAGES = 10;

const OG_STATS_API_URL = "https://stats.apedao.finance:8055/labra/get_collection_stats?market_id=soon&coll_id=0x3dfb05c0613f059c4ec2c4d848cddea26bd2b480";

let txList = [
  {
    id: 1,
    image:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/1074.png",
    name: "OG Ape #1074",
    event: "Sold",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 2,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/69.png",
    name: "OG Ape #69",
    event: "Listed",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 3,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/420.png",
    name: "OG Ape #420",
    event: "Modified",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 4,
    image:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/1074.png",
    name: "OG Ape #1074",
    event: "Sold",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 5,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/69.png",
    name: "OG Ape #69",
    event: "Listed",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 6,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/420.png",
    name: "OG Ape #420",
    event: "Modified",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 7,
    image:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/1074.png",
    name: "OG Ape #1074",
    event: "Sold",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 8,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/69.png",
    name: "OG Ape #69",
    event: "Listed",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 9,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/420.png",
    name: "OG Ape #420",
    event: "Modified",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 10,
    image:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/1074.png",
    name: "OG Ape #1074",
    event: "Sold",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
  {
    id: 11,
    image: "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/69.png",
    name: "OG Ape #69",
    event: "Listed",
    priceImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
    price: 50,
    from: "0x170...1ED8",
    to: "0x170...1ED8",
    date: "22/02/2023, 13:00",
    txImage:
      "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25.png",
  },
];

function Banner({ label, value }) {
  return (
    <div className="banner">
      <div className="banner-label">{label}</div>
      <div className="banner-number">{value}</div>
    </div>
  );
}

function Home({ vault, og, lil, account }) {
  const [activeButton, setActiveButton] = useState("Collection");
  const [ogApesVault, setOGApesVault] = useState("N/A");
  const [lilApesVault, setLilApesVault] = useState("N/A");
  const [ogApesListed, setOGApesListed] = useState("N/A");
  const [lilApesListed, setLilApesListed] = useState("N/A");
  const [ogApesFloor, setOGApesFloor] = useState("N/A");
  const [lilApesFloor, setLilApesFloor] = useState("N/A");
  const [uniqueApeOwners, setUniqueApeOwners] = useState("N/A");
  const [apeinPrice, setAPEinPrice] = useState("N/A");
  const [treasuryValue, setTreasuryValue] = useState("N/A");
  const [topTreasuryHoldings, setTopTreasuryHoldings] = useState([
    "N/A",
    "N/A",
    "N/A",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(MAX_RECORDS_PER_PAGE);

  const fetchStatsData = async () => {
    try {
      const response = await fetch(OG_STATS_API_URL);
      const data = await response.json();

      if (data.status === 'success') {
        setOGApesListed(data.stats.count_on_trade);
        setOGApesFloor(data.stats.floor_price_trade);
        setUniqueApeOwners(data.stats.unique_owners);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const retrieveData = async (retryCount = 0) => {
      try {
        const ogApesInVault = await vault.allHoldings(OG_HOLDING_INDEX);
        const lilApesInVault = await vault.allHoldings(LIL_HOLDING_INDEX);
        setOGApesVault(ogApesInVault.length);
        setLilApesVault(lilApesInVault.length);
      } catch (error) {
        if (retryCount < 5) {
          setTimeout(() => retrieveData(retryCount + 1), 3000);
        } else {
          console.log("Error retrieving data:", error);
          NotificationManager.error(
            "Can't retrieve stats data. Try later.",
            "ERROR",
            5000
          );
        }
      }
    };

    retrieveData();
  }, [vault]);

  useEffect(() => {
    fetchStatsData(); // Fetch data initially

    const intervalId = setInterval(() => {
      fetchStatsData();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveButton(activeButton === buttonName ? null : buttonName);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = txList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(txList.length / recordsPerPage);

  return (
    <div className="home-container">
      <div className="home-stats">
        <p className="home-stats-title">APEDAO STATS</p>
        <div className="home-stats-grid">
          <Banner label="OG Apes Listed" value={ogApesListed} />
          <Banner label="OG Apes Floor" value={ogApesFloor} />
          <Banner label="OG Apes in Vault" value={ogApesVault} />
          <Banner label="Unique Ape Owners" value={uniqueApeOwners} />
          <Banner label="Lil' Apes Listed" value={lilApesListed} />
          <Banner label="Lil' Apes Floor" value={lilApesFloor} />
          <Banner label="Lil' Apes in Vault" value={lilApesVault} />
          <Banner label="APEin price" value={apeinPrice} />
          <Banner label="Treasury Value" value={treasuryValue} />
          <div className="last-row">
            <div className="banner-label">Top Treasury Holdings</div>
            <div className="banner-number">{topTreasuryHoldings[0]}</div>
          </div>
        </div>
      </div>
      <div className="home-market-activity">
        <div className="home-market-activity-header">
          <p className="home-stats-title">MARKET ACTIVITY</p>
          <div className="home-market-activity-filters">
            <label>FILTERS:</label>
            {[
              "Collection",
              "Sold",
              "Modified",
              "Listed",
              "Delisted",
              "Marketplace",
            ].map((buttonName) => (
              <button
                key={buttonName}
                onClick={() => handleButtonClick(buttonName)}
                className={activeButton === buttonName ? "active" : ""}
              >
                {buttonName}
              </button>
            ))}
          </div>
        </div>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <ActivityList items={currentRecords} />
        </div>

        {txList.length > MAX_RECORDS_PER_PAGE ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPages={MAX_PAGES}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Home;
