import "./style.css";

function Pagination({ nPages, currentPage, setCurrentPage, maxPages }) {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const numPagesToDisplay = Math.min(maxPages, nPages);
  const startPage = Math.max(
    currentPage - Math.floor(numPagesToDisplay / 2),
    1
  );
  const endPage = Math.min(startPage + numPagesToDisplay - 1, nPages);
  const displayedPageNumbers = pageNumbers.slice(startPage - 1, endPage);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const firstPage = () => {
    setCurrentPage(1);
  };
  const lastPage = () => {
    setCurrentPage(nPages);
  };
  return (
    <nav className="pagination-nav">
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <a className="page-link" onClick={firstPage}>
            First
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" onClick={prevPage}>
            Previous
          </a>
        </li>
        {displayedPageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage === pgNumber ? "active" : ""} `}
          >
            <a onClick={() => setCurrentPage(pgNumber)} className="page-link">
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a className="page-link" onClick={nextPage}>
            Next
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" onClick={lastPage}>
            Last
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
