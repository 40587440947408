import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
//Initialize GA4
ReactGA.initialize("G-B7LJNP7VD2");

render(<App />, rootElement);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
