import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import "./style.css";
import ModalApe from "../ModalApe/ModalApe.js";
import Checkbox from "./../Checkbox/Checkbox.js";
import FilterSidebar from "./../FilterSidebar/FilterSidebar.js";
import CollectionToggle from "./../CollectionToggle/CollectionToggle.js";
import Pagination from "./../Pagination/Pagination.js";
import InfoIcon from "./../InfoIcon/InfoIcon.js";
import DialogModal from "./../DialogModal/DialogModal.js";
import * as CollectionsMetadata from "./../../collectionMetada/collectionsMetadata.js";
import { NotificationManager } from "react-notifications";
import filter_icon from "../../images/filter-icon.png";

const S3_OG = "https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/";
const S3_LIL =
  "https://apedao-images.s3.eu-central-1.amazonaws.com/lilapes-demo/";

const OG_IMAGE_IPFS_URL = "QmNbZSywnbrf4HSAzDX1jvdvwCkHKgRACChZXF4j1t3X4S";

const OG_BASE_PRICE = 500;
const LIL_BASE_PRICE = 100;

const MAX_RECORDS_PER_PAGE = 20;
const MAX_PAGES = 10;

const APES_FILTER_API = "https://stats.apedao.finance:8051/v1/filter_og_apes2?";

const initialMaxPages = window.innerWidth <= 500 ? 5 : 10;

function MyApes({ vault, og, lil, account, fees, balance }) {
  const [txWait, setTxWait] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ogItems, setOGItems] = useState([]);
  const [ogItemsLoaded, setOGItemsLoaded] = useState(false);
  const [lilItems, setLilItems] = useState([]);
  const [lilItemsLoaded, setLilItemsLoaded] = useState(false);
  const [ogApesSupply, setOgApesSupply] = useState("---");
  const [lilApesSupply, setLilApesSupply] = useState("---");
  const [allChecked, setAllChecked] = useState(false);
  const [collectionToLoad, setCollectionToLoad] = useState("OG APES");
  const [cardClicked, setCardClicked] = useState(false);
  const [mapCardClicked, setMapCardClicked] = useState(new Map());
  const [selectedAPEinPrice, setSelectedAPEinPrice] = useState(0);
  const [startFilter, setStartFilter] = useState(true);
  const [filterActive, setFilterActive] = useState(false);
  const [filteredApeIds, setFilteredApeIds] = useState([]);
  const [filterReset, setFilterReset] = useState(false);
  const [filteredApesArray, setFilteredApesArray] = useState([]);
  const [dialogModalShow, setDialogModalShow] = useState(false);
  const [rightFilterVisible, setRightFilterVisible] = useState(false);

  const [maxPages, setMaxPages] = useState(initialMaxPages);

  const [sellModalShow, setSellModalShow] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(MAX_RECORDS_PER_PAGE);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const collection = collectionToLoad === "OG APES" ? ogItems : lilItems;
  const currentRecords = collection.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages =
    collectionToLoad === "OG APES"
      ? Math.ceil(ogApesSupply / recordsPerPage)
      : Math.ceil(lilApesSupply / recordsPerPage);

  const modalTitle = collectionToLoad === "OG APES" ? "OG APE" : "LIL' APE";
  const modalCollectionPrice =
    collectionToLoad === "OG APES" ? OG_BASE_PRICE : LIL_BASE_PRICE;

  const filterCollection =
    collectionToLoad === "OG APES" ? "&collection=1" : "&collection=2";

  const handleRightSidebarFilterClose = () => {
    setRightFilterVisible(false);
  };

  function handleAllSell() {
    if (collectionToLoad === "OG APES" && ogItems.length <= 0) {
      NotificationManager.warning("You don't have OG Apes", "WARNING", 5000);
      return;
    } else if (collectionToLoad === "LIL' APES" && lilItems.length <= 0) {
      NotificationManager.warning("You don't have Lil' Apes", "WARNING", 5000);
      return;
    }
    setAllChecked(!allChecked);
  }

  function handleSellButtonClick() {
    const noApesSelected =
      (ogItems.length <= 0 && lilItems.length <= 0) ||
      (!cardClicked && !allChecked);

    if (
      (collectionToLoad === "OG APES" &&
        (ogItems.length <= 0 || noApesSelected)) ||
      (collectionToLoad === "LIL' APES" &&
        (lilItems.length <= 0 || noApesSelected))
    ) {
      NotificationManager.warning(
        "Please select at least one Ape",
        "WARNING",
        5000
      );
      return false;
    }

    return true;
  }

  const handleFilterNumberChange = async (value) => {
    if (value === 0) {
      // Fitler has been cleared
      if (collectionToLoad === "OG APES") {
        await loadMyApesOGApes();
      } else if (collectionToLoad === "LIL' APES") {
        await loadMyApesLilApes();
      }
      return;
    }

    setFilteredApeIds([value]);
    setStartFilter(true);
    setFilterReset(true);

    if (collectionToLoad === "OG APES") {
      loadMyApesFilteredApes([value], true, "OG");
    } else if (collectionToLoad === "LIL' APES") {
      loadMyApesFilteredApes([value], true, "LIL");
    }
  };

  const handleFilterChange = async (selectedValues) => {
    try {
      if (!Object.values(selectedValues).some((array) => array.length > 0)) {
        if (collectionToLoad === "OG APES") {
          setOGItems([]);
        } else if (collectionToLoad === "LIL' APES") {
          setLilItems([]);
        }
        setStartFilter(false);
        setFilterActive(false);
        return;
      }

      setFilterActive(true);

      const filteredItems = Object.entries(selectedValues)
        .filter(([name, subItems]) => subItems.length > 0)
        .map(([name, subItems]) => `${name}=${subItems.join(",")}`)
        .join("&");

      const response = await fetch(
        APES_FILTER_API + filteredItems + filterCollection
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      let apeIds = [];
      const filterKey = collectionToLoad === "OG APES" ? "Ape" : "Lil' Ape";

      apeIds = data.filt_apes
        .filter((value) => value.startsWith(filterKey))
        .map((value) => value.split("#")[1]);

      setFilteredApeIds(apeIds);
      setStartFilter(true);
      setFilterReset(true);

      if (currentPage !== 1) {
        setFilteredApesArray([]);
        setCurrentPage(1);
      } else {
        if (collectionToLoad === "OG APES") {
          loadMyApesFilteredApes(apeIds, true, "OG");
        } else if (collectionToLoad === "LIL' APES") {
          loadMyApesFilteredApes(apeIds, true, "LIL");
        }
      }
    } catch (error) {
      NotificationManager.error(
        "Can't retrieve filtered data right now",
        "ERROR",
        5000
      );
      console.log(error);
    }
  };

  function handleCardClicked(item) {
    // This is required to make the map update trigger a re-render
    let count = 0;
    let tempMap = new Map(Array.from(mapCardClicked).slice());
    tempMap.set(item.itemId, !tempMap.get(item.itemId));
    setMapCardClicked(tempMap);
    for (let i = 0; i < Array.from(tempMap).length; ++i) {
      if (Array.from(tempMap)[i][1]) {
        ++count;
      }
    }

    if (collectionToLoad === "OG APES") {
      setSelectedAPEinPrice(count * OG_BASE_PRICE);
    } else if (collectionToLoad === "LIL' APES") {
      setSelectedAPEinPrice(count * LIL_BASE_PRICE);
    }

    if (count > 0) {
      setCardClicked(true);
      return;
    }
    setCardClicked(false);
    mapCardClicked.clear();
    setMapCardClicked(mapCardClicked);
  }

  const handleCollectionChange = (collection) => {
    setCollectionToLoad(collection);
  };

  function handleInfoIconClick() {
    setDialogModalShow(true);
  }

  function removeClickedItem(item) {
    let aux = new Map(Array.from(mapCardClicked).slice());

    aux.delete(item);

    if (collectionToLoad === "OG APES") {
      setSelectedAPEinPrice(selectedAPEinPrice - OG_BASE_PRICE);
    } else if (collectionToLoad === "LIL' APES") {
      setSelectedAPEinPrice(selectedAPEinPrice - LIL_BASE_PRICE);
    }

    setMapCardClicked(aux);

    if (aux.size === 0) {
      setSellModalShow(false);
      setCardClicked(false);
    }
  }

  const loadMyApesOGApes = async () => {
    const offset = (currentPage - 1) * recordsPerPage; // calculate offset based on current page

    if (offset < ogItems.length) {
      if (ogItems[offset + 1] != null) {
        return;
      }
    }

    setLoading(true);
    const holdings = (await og.balanceOf(account)).toNumber();
    const aux = [];

    for (let i = offset; i < holdings && i < offset + recordsPerPage; ++i) {
      const tokenId = await og.tokenOfOwnerByIndex(account, i);

      const metadata = CollectionsMetadata.ogApeMetadata[tokenId - 1];

      const updatedImage = metadata.image.replace(
        "ipfs://NewUriToReplace",
        "ipfs://" + OG_IMAGE_IPFS_URL
      );

      aux.push({
        itemId: metadata.edition,
        name: metadata.name,
        image: updatedImage,
      });
    }

    // If the array is empty or the offset is greater than the array length, add null values
    if (offset >= ogItems.length) {
      const diff = offset - ogItems.length;
      for (let i = 0; i < diff; i++) {
        ogItems.push(null);
      }
    }

    // Concatenate the new items to the array starting from the offset position
    if (aux.length > 0) {
      setOGItems(ogItems.slice(0, offset).concat(aux));
    }

    setOGItemsLoaded(true);
    setOgApesSupply(holdings);
    setLoading(false);
  };

  const loadMyApesLilApes = async () => {
    const offset = (currentPage - 1) * recordsPerPage; // calculate offset based on current page

    if (offset < lilItems.length) {
      if (lilItems[offset + 1] != null) {
        return;
      }
    }

    setLoading(true);
    const holdings = (await lil.balanceOf(account)).toNumber();
    const aux = [];

    for (let i = offset; i < holdings && i < offset + recordsPerPage; ++i) {
      const tokenId = await lil.tokenOfOwnerByIndex(account, i);

      const metadata = CollectionsMetadata.lilApeMetadata[tokenId - 1];

      aux.push({
        itemId: metadata.edition,
        name: metadata.name,
        image: metadata.image,
      });
    }

    // If the array is empty or the offset is greater than the array length, add null values
    if (offset >= lilItems.length) {
      const diff = offset - lilItems.length;
      for (let i = 0; i < diff; i++) {
        lilItems.push(null);
      }
    }

    // Concatenate the new items to the array starting from the offset position
    if (aux.length > 0) {
      setLilItems(lilItems.slice(0, offset).concat(aux));
    }
    setLilItemsLoaded(true);
    setLilApesSupply(holdings);
    setLoading(false);
  };

  const loadMyApesFilteredApes = async (apeIds, filterRes, type) => {
    let reset = filterRes || filterReset ? true : false;
    const items = type === "OG" ? ogItems : lilItems;
    const setItems = type === "OG" ? setOGItems : setLilItems;
    const setItemsLoaded = type === "OG" ? setOGItemsLoaded : setLilItemsLoaded;
    const setApesSupply = type === "OG" ? setOgApesSupply : setLilApesSupply;
    const apesMetadata =
      type === "OG"
        ? CollectionsMetadata.ogApeMetadata
        : CollectionsMetadata.lilApeMetadata;

    if (reset) {
      setFilterReset(false);
    }

    const offset = (currentPage - 1) * recordsPerPage;

    if (offset < items.length && !startFilter && !reset) {
      if (items[offset + 1] != null) {
        return;
      }
    }

    setLoading(true);

    if (apeIds === filteredApeIds && filteredApesArray.length > 0) {
      const filteredMetadata = filteredApesArray
        .slice(offset, offset + recordsPerPage)
        .map((apeId) => {
          const metadata = apesMetadata[apeId - 1];
          return {
            itemId: metadata.edition,
            name: metadata.name,
            image:
              type === "OG"
                ? metadata.image.replace(
                    "ipfs://NewUriToReplace",
                    "ipfs://" + OG_IMAGE_IPFS_URL
                  )
                : metadata.image,
          };
        });

      const itemsToConcat = filteredMetadata.concat(
        new Array(
          Math.max(0, offset + recordsPerPage - filteredMetadata.length)
        ).fill(null)
      );

      if (offset >= items.length) {
        const diff = offset - items.length;
        items.push(...new Array(diff).fill(null));
      }

      if (startFilter || reset) {
        setItems(itemsToConcat);
        setStartFilter(false);
      } else {
        setItems(items.slice(0, offset).concat(itemsToConcat));
      }

      setItemsLoaded(true);
      setApesSupply(filteredApesArray.length);
      setLoading(false);
    } else {
      const holdings =
        type === "OG"
          ? (await og.balanceOf(account)).toNumber()
          : (await lil.balanceOf(account)).toNumber();
      const auxFilteredApesArray = [];

      if (holdings > apeIds.length) {
        let owner;
        for (let i = 0; i < apeIds.length; ++i) {
          try {
            owner =
              type === "OG"
                ? await og.ownerOf(apeIds[i])
                : await lil.ownerOf(apeIds[i]);

            if (owner.toLowerCase() === account) {
              auxFilteredApesArray.push(apeIds[i]);
            }
          } catch (error) {
            console.log(error);
            --i;
          }
        }
      } else {
        let tokenId;
        for (let i = 0; i < holdings; ++i) {
          try {
            tokenId =
              type === "OG"
                ? await og.tokenOfOwnerByIndex(account, i)
                : await lil.tokenOfOwnerByIndex(account, i);
            if (apeIds.includes(tokenId.toString())) {
              auxFilteredApesArray.push(tokenId.toString());
            }
          } catch (error) {
            console.log(error);
            --i;
          }
        }
      }

      setFilteredApesArray(auxFilteredApesArray);

      const filteredMetadata = auxFilteredApesArray
        .slice(offset, offset + recordsPerPage)
        .map((apeId) => {
          const metadata = apesMetadata[apeId - 1];
          return {
            itemId: metadata.edition,
            name: metadata.name,
            image:
              type === "OG"
                ? metadata.image.replace(
                    "ipfs://NewUriToReplace",
                    "ipfs://" + OG_IMAGE_IPFS_URL
                  )
                : metadata.image,
          };
        });

      const itemsToConcat = filteredMetadata.concat(
        new Array(
          Math.max(0, offset + recordsPerPage - filteredMetadata.length)
        ).fill(null)
      );

      if (offset >= items.length) {
        const diff = offset - items.length;
        items.push(...new Array(diff).fill(null));
      }

      if (startFilter || reset) {
        setItems(itemsToConcat);
        setStartFilter(false);
      } else {
        setItems(items.slice(0, offset).concat(itemsToConcat));
      }

      setItemsLoaded(true);
      setApesSupply(auxFilteredApesArray.length);
      setLoading(false);
    }
  };

  const sellMultipleNFTs = async (type) => {
    const isBalanceAvailable = checkAvailableBalance();

    if (!isBalanceAvailable) {
      return;
    }

    try {
      setTxWait(true);

      // Check NFTs approval
      const selectedNFTs = allChecked
        ? filterActive
          ? filteredApesArray
          : type === "OG"
          ? ogItems.map((item) => item.itemId)
          : lilItems.map((item) => item.itemId)
        : Array.from(mapCardClicked)
            .filter(([nftId, isSelected]) => isSelected)
            .map(([nftId]) => nftId);

      const nftContract = type === "OG" ? og : lil;
      const approvedAddressPromises = selectedNFTs.map((nftId) =>
        nftContract.getApproved(nftId)
      );
      const approvedForAllPromises = selectedNFTs.map((nftId) =>
        nftContract.isApprovedForAll(account, vault.address)
      );
      const approvedAddresses = await Promise.all(approvedAddressPromises);
      const approvedForAll = await Promise.all(approvedForAllPromises);

      for (let i = 0; i < selectedNFTs.length; ++i) {
        if (
          approvedAddresses[i].toUpperCase() !== vault.address.toUpperCase() &&
          !approvedForAll[i]
        ) {
          setApproveModalShow(true);
          return;
        }
      }

      // Sell NFTs
      const nftTypeIndex = type === "OG" ? 0 : 1;
      await (
        await vault.mint(selectedNFTs, nftTypeIndex, {
          gasLimit: 2000000 * selectedNFTs.length,
          gasPrice: 100000000000,
        })
      ).wait();

      NotificationManager.success(
        `NFT${selectedNFTs.length > 1 ? "s" : ""} have been sold`,
        "Success",
        5000
      );

      mapCardClicked.clear();
      setMapCardClicked(mapCardClicked);
      setCardClicked(false);
      setStartFilter(false);
      setFilterActive(false);
      if (type === "OG") {
        setOGItems([]);
      } else {
        setLilItems([]);
      }
    } catch (e) {
      if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Unknown error. Please try again later.",
          "Error",
          5000
        );
        console.log(e.message);
      }
    } finally {
      setTxWait(false);
      setSellModalShow(false);
      setAllChecked(false);
    }
  };

  const approveCollection = async (type) => {
    const isBalanceAvailable = checkAvailableBalance();

    if (!isBalanceAvailable) {
      return;
    }

    setTxWait(true);
    try {
      if (type === "OG") {
        await (await og.setApprovalForAll(vault.address, true)).wait();
      } else if (type === "LIL") {
        await (await lil.setApprovalForAll(vault.address, true)).wait();
      }

      NotificationManager.success(
        "NFT has been approved. You can sell an NFT now.",
        "Success",
        5000
      );
    } catch (e) {
      if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("Please enable Blind signing")) {
        NotificationManager.error(
          "Please enable Blind signing or Contract data in the Ethereum app Settings",
          "Error",
          5000
        );
      } else {
        console.log(e);
        NotificationManager.error(e.message, "Error", 5000);
      }
    }
    setTxWait(false);
    setApproveModalShow(false);
  };

  function checkAvailableBalance() {
    let hasEnoughBalance = false;
    if (balance > 0) {
      hasEnoughBalance = true;
    }
    if (!hasEnoughBalance) {
      NotificationManager.error(
        "You don't have any $SMR in your wallet. $SMR is required to pay gas fees.",
        "Error",
        5000
      );
    }
    return hasEnoughBalance;
  }

  useEffect(() => {
    setCardClicked(false);
    setMapCardClicked(new Map());
    setCurrentPage(1);

    if (collectionToLoad === "OG APES" && !ogItemsLoaded) {
      loadMyApesOGApes();
    } else if (collectionToLoad === "LIL' APES" && !lilItemsLoaded) {
      loadMyApesLilApes();
    }
  }, [collectionToLoad]);

  useEffect(() => {
    setLoading(false);
    if (filterActive) {
      if (collectionToLoad === "OG APES") {
        loadMyApesFilteredApes(filteredApeIds, 0, "OG");
      } else if (collectionToLoad === "LIL' APES") {
        loadMyApesFilteredApes(filteredApeIds, 0, "LIL");
      }
    } else {
      if (collectionToLoad === "OG APES") {
        loadMyApesOGApes();
      } else if (collectionToLoad === "LIL' APES") {
        loadMyApesLilApes();
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (isMounted) {
      if (ogItems.length === 0 && !filterActive) {
        loadMyApesOGApes();
      }
    } else {
      setIsMounted(true);
    }
  }, [ogItems]);

  useEffect(() => {
    if (isMounted) {
      if (lilItems.length === 0 && !filterActive) {
        loadMyApesLilApes();
      }
    } else {
      setIsMounted(true);
    }
  }, [lilItems]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 500) {
        setMaxPages(5);
      } else {
        setMaxPages(10);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div className="myapes">
      <div>
        <ModalApe
          title={`SELL ${modalTitle}`}
          show={sellModalShow}
          buttonClick={
            collectionToLoad === "OG APES"
              ? () => sellMultipleNFTs("OG")
              : () => sellMultipleNFTs("LIL")
          }
          buttonTitle={"SELL"}
          onHide={() => setSellModalShow(false)}
          clickedItem=""
          loading={txWait}
          isSell={true}
          collectionPrice={modalCollectionPrice}
          collection={collectionToLoad}
          fees={fees}
          selectedAPEinPrice={
            allChecked
              ? collectionToLoad === "OG APES"
                ? filterActive
                  ? filteredApesArray.length * OG_BASE_PRICE
                  : ogItems.length * OG_BASE_PRICE
                : filterActive
                ? filteredApesArray.length * LIL_BASE_PRICE
                : lilItems.length * LIL_BASE_PRICE
              : selectedAPEinPrice
          }
          selectedApes={Array.from(mapCardClicked)
            .filter(([, isSelected]) => isSelected)
            .map(([ape]) => ape)}
          onRemoveClicked={removeClickedItem}
          allChecked={allChecked}
        />

        <ModalApe
          title={`APPROVE ${modalTitle} SC`}
          show={approveModalShow}
          buttonClick={
            collectionToLoad === "OG APES"
              ? () => approveCollection("OG")
              : () => approveCollection("LIL")
          }
          buttonTitle={"APPROVE"}
          onHide={() => setApproveModalShow(false)}
          loading={txWait}
          isSell={true}
          isApprove={true}
          collection={collectionToLoad}
        />
      </div>
      <div className="myapes-filter-sidebar-left">
        <FilterSidebar
          collection={collectionToLoad}
          onFilterChange={handleFilterChange}
          onFilterApeNumberChange={handleFilterNumberChange}
          isFilterActive={filterActive}
        />
      </div>
      <div className="myapes-content-container">
        <div className="myapes-subheader">
          <div className="myapes-subheader-row1">
            <CollectionToggle
              title1="OG APES"
              title2="LIL' APES"
              onChange={handleCollectionChange}
            />
            <InfoIcon clickFunction={handleInfoIconClick} />
            <DialogModal
              onHide={() => setDialogModalShow(false)}
              show={dialogModalShow}
              content="Select apes that you want to sell."
            />
            <button
              className="myapes-subheader-button"
              onClick={() => {
                const selected = handleSellButtonClick();
                if (selected) {
                  setSellModalShow(true);
                }
              }}
              disabled={txWait}
            >
              {txWait ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "10px" }}
                />
              ) : null}
              Sell
            </button>
            <button
              className="myapes-subheader-filter-button"
              onClick={() => {
                setRightFilterVisible(!rightFilterVisible);
              }}
            >
              <img src={filter_icon} alt="filter icon"></img>
            </button>
          </div>
          <div className="myapes-subheader-row2">
            <span style={{ alignSelf: "center" }}>
              {collectionToLoad === "OG APES"
                ? `${ogApesSupply} OG Apes in your wallet`
                : `${lilApesSupply} Lil Apes in your wallet`}
            </span>
            <Checkbox
              label="Select All"
              value={allChecked}
              onChange={handleAllSell}
              isCardClicked={false}
            />
          </div>
        </div>
        <div className="myapes-content">
          {loading ? (
            <div className="loading-container">
              <Spinner animation="border" className="loading-spinner" />
              <p className="loading-text">Loading...</p>
            </div>
          ) : (
            <Row xs={1} md={2} lg={4} className="g-4">
              {currentRecords.map((item, idx) => {
                if (!item) {
                  return null;
                }

                const isClicked = mapCardClicked.get(item.itemId);
                const cardClass = `card-custom${isClicked ? "-clicked" : ""}`;

                const imgSrc =
                  collectionToLoad === "LIL' APES"
                    ? `${S3_LIL}${item.itemId}.png`
                    : `${S3_OG}${item.itemId}.png`;

                return (
                  <Col key={idx} className="card-col-custom">
                    <Card
                      onClick={() => handleCardClicked(item)}
                      className={cardClass}
                    >
                      <Card.Img src={imgSrc} className="card-image-custom" />
                      <Card.Body className="card-body-custom">
                        <Card.Title className="card-title-custom">
                          {item.name.toUpperCase()}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>

        {(collectionToLoad === "OG APES" &&
          ogApesSupply > MAX_RECORDS_PER_PAGE) ||
        (collectionToLoad === "LIL' APES" &&
          lilApesSupply > MAX_RECORDS_PER_PAGE) ? (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPages={maxPages}
          />
        ) : null}
      </div>
      <div
        className={
          rightFilterVisible
            ? "myapes-filter-sidebar-right"
            : "myapes-filter-sidebar-right-hidden"
        }
      >
        <FilterSidebar
          collection={collectionToLoad}
          onFilterChange={handleFilterChange}
          onFilterClose={handleRightSidebarFilterClose}
          onFilterApeNumberChange={handleFilterNumberChange}
          isFilterActive={filterActive}
        />
      </div>
    </div>
  );
}

export default MyApes;
