import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useCallback } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import apein_logo from "../images/apein_token_2.svg";
import shimmer_icon from "../images/smr_logo.svg";
import { NotificationManager } from "react-notifications";
import detectEthereumProvider from "@metamask/detect-provider";

const Navigation = ({
  vault,
  og,
  lil,
  web3Handler,
  account,
  balance,
  shimmerBalance,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdown = useRef(null);

  const tokenAddress = "0x370A28811df513731b3ee84d6C1A6347733BDf67";
  const tokenSymbol = "APEin";
  const tokenDecimals = 18;

  const closeDropdown = useCallback(
    (e) => {
      if (
        dropdown.current &&
        !openDropdown &&
        !dropdown.current.contains(e.target)
      ) {
        setOpenDropdown(false);
      }
    },
    [openDropdown]
  );

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleDisconnect = () => {
    localStorage.setItem("ConnectionStatus", "Disconnected");
    window.dispatchEvent(new Event("ConnectionStatusDisconnected"));
  };

  const importAPEinToken = async () => {
    const ethereumProvider = await detectEthereumProvider();
    try {
      // wasAdded is a boolean. Like any RPC method, an error can be thrown.
      const wasAdded = await ethereumProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC-20 tokens, but eventually more!
          options: {
            address: tokenAddress, // The address of the token.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 characters.
            decimals: tokenDecimals, // The number of decimals in the token.
            image:
              "https://app.apedao.finance/static/media/apein_token_2.d05116aba0c058a60d29af74bcc63f19.svg", // A string URL of the token logo.
          },
        },
      });

      if (wasAdded) {
        NotificationManager.success(
          "$APEin token successfuly added to Metamask",
          "Success",
          3000
        );
      }
    } catch (error) {
      console.log(error);
      if (error.message.includes("User rejected")) {
        NotificationManager.warning("User rejected request", "Rejected", 5000);
      } else {
        NotificationManager.error(
          "Error adding APEin token to Metamask",
          "Error",
          5000
        );
      }
    }
  };

  const handleRevokeOG = async () => {
    try {
      // Estimate gas required for setting approval
      let estimation = await og.estimateGas.setApprovalForAll(
        vault.address,
        false
      );

      // Add 10% to the gas estimation
      estimation = estimation.add(estimation.mul(10).div(100));

      const tx = await og.setApprovalForAll(vault.address, false, {
        gasLimit: estimation,
      });

      await tx.wait();
      NotificationManager.success("NFTs have been revoked", "Success", 3000);
    } catch (e) {
      if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("gas too low")) {
        NotificationManager.error("Low gas fees error", "Error", 5000);
      } else {
        console.log(e.message);
        NotificationManager.error(e.message, "Error", 5000);
      }
    }
  };

  const handleRevokeLil = async () => {
    try {
      // Estimate gas required for setting approval
      let estimation = await lil.estimateGas.setApprovalForAll(
        vault.address,
        false
      );

      // Add 10% to the gas estimation
      estimation = estimation.add(estimation.mul(10).div(100));

      const tx = await lil.setApprovalForAll(vault.address, false, {
        gasLimit: estimation,
      });

      await tx.wait();
      NotificationManager.success("NFTs have been revoked", "Success", 3000);
    } catch (e) {
      if (e.message.includes("user rejected transaction")) {
        NotificationManager.warning(
          "User rejected transaction",
          "Rejected",
          5000
        );
      } else if (e.message.includes("gas too low")) {
        NotificationManager.error("Low gas fees error", "Error", 5000);
      } else {
        NotificationManager.error(e.message, "Error", 5000);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [closeDropdown]);

  return (
    <Navbar expand="lg" className="color-nav" variant="light">
      <Container>
        <Navbar.Brand className="navbar-brand" href="http://apedao.finance">
          &nbsp; ApeDAO
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              className="navbar-link"
              as={Link}
              to="/home"
              eventKey="/home"
              activeclassname="active"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className="navbar-link"
              as={Link}
              to="/vaults"
              eventKey="/vaults"
              activeclassname="active"
            >
              Vaults
            </Nav.Link>
            <Nav.Link
              className="navbar-link"
              as={Link}
              to="/my-apes"
              eventKey="/my-apes"
              activeclassname="active"
            >
              My Apes
            </Nav.Link>
          </Nav>
          <Nav>
            {account ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="navbar-info"
              >
                <div className="navbar-APEin-icon" onClick={importAPEinToken}>
                  <img src={apein_logo} alt=""></img>
                  <label>{balance}</label>
                </div>
                <div
                  style={{
                    marginRight: "30px",
                  }}
                >
                  <img
                    src={shimmer_icon}
                    style={{
                      width: "35px",
                      height: "35px",
                      marginTop: "-5px",
                      marginRight: "10px",
                    }}
                    alt=""
                  ></img>
                  <label>{shimmerBalance}</label>
                </div>
                <div className="dropdown" ref={dropdown}>
                  <button
                    className="navbar-info-button"
                    onClick={handleOpenDropdown}
                  >
                    {account.slice(0, 5) + "..." + account.slice(38, 42)}
                    <i
                      style={{
                        marginLeft: "15px",
                        marginBottom: "5px",
                      }}
                      className="arrow down"
                    ></i>
                  </button>
                  {openDropdown ? (
                    <ul className="menu">
                      <li className="menu-item">
                        <button onClick={handleDisconnect}>Disconnect</button>
                      </li>
                      <li className="menu-item">
                        <button onClick={handleRevokeOG}>Revoke OG</button>
                      </li>
                      <li className="menu-item">
                        <button onClick={handleRevokeLil}>Revoke Lil</button>
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
            ) : (
              <button
                onClick={() => {
                  localStorage.setItem("ConnectionStatus", "Connected");
                  web3Handler();
                }}
                className="color-button"
              >
                Connect Wallet
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
