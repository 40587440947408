import "./style.css";

function DialogModal({ onHide, show, content }) {
  if (!show) {
    return null;
  }
  return (
    <div className="dialog-container">
      <div className="dialog-rectangle">
        <p className="dialog-text">{content}</p>
        <button className="dialog-button" onClick={onHide}>
          Close
        </button>
      </div>
    </div>
  );
}

export default DialogModal;
