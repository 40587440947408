import "./style.css";
import shimmer_icon from "../../images/smr_logo.svg";
import soonaverse_icon from "../../images/soonaverse-logo.svg";

function ListItem({ item }) {
  return (
    <tr className="list-item-row">
      <td>
        <img
          className="activity-list-item-img"
          src={item.image}
          alt={item.name}
        />
        <span className="activity-list-item-label">{item.name}</span>
      </td>
      <td className="activity-list-event-label">{item.event}</td>
      <td className="activity-list-price">
        <img
          className="activity-list-price-img"
          src={shimmer_icon}
          alt="price"
        />
        <span className="activity-list-price-label">{item.price}</span>
      </td>
      <td className="activity-list-from-label">{item.from}</td>
      <td className="activity-list-to-label"> {item.to}</td>
      <td className="activity-list-date-label">{item.date}</td>
      <td>
        <img className="activity-list-tx-img" src={soonaverse_icon} alt="tx" />
      </td>
    </tr>
  );
}

function ActivityList({ items }) {
  return (
    <table style={{ marginTop: "50px", width: "100%" }}>
      <thead>
        <tr>
          <th>ITEM</th>
          <th>EVENT</th>
          <th>PRICE</th>
          <th>FROM</th>
          <th>TO</th>
          <th>DATE</th>
          <th>TX</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <ListItem key={item.id} item={item} />
        ))}
      </tbody>
    </table>
  );
}

export default ActivityList;
